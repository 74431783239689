import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Grid, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate, useParams } from 'react-router';
import FormData from 'form-data';
import LoginLayout from '../../components/LoginLayout';
import { InputField } from '../../components/InputField';
import { useSnackbar } from 'notistack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';

const ResetPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      confirmPassword: Yup.string()
      .transform(value => value?.trim())
      .when("newPassword", { // this should match with input field name
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("newPassword")],
          "Password does not match"
        ),
      }),
      newPassword: Yup.string()
      .transform(value => value?.trim())
      .min(8, "New Password must be at least 8 characters long.")
      .matches(/(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).*$/, {message: "New Password must contain at least one digit, one uppercase letter, and one lowercase letter."})
      .required('No password provided.'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        helpers.setSubmitting(true);
        const data = new FormData();
        data.append('r', token);
        data.append('newPasswd', values.newPassword?.trim());
        data.append('newPasswd2', values.confirmPassword?.trim());
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/restAuthApi/resetMyPasswordAction`, data); //'http://135.181.205.50:8080/restAuthApi/resetMyPasswordAction', data);
        if (response.data?.status === 'SUCCESS') {
          enqueueSnackbar(response.data.message, {variant: 'success'});
          navigate('/login');
        } else {
          enqueueSnackbar(response.data.message, {variant: 'error'});
        }
      } catch (error) {
        enqueueSnackbar(error.message, {variant: 'error'});
      } finally {
        helpers.setSubmitting(false);
      }
    }
  });
  
  return (
    <>
      <LoginLayout>
        <Grid container spacing={2} direction='column'>
          <Grid item>
            <IconButton color='primary' onClick={() => navigate('/login')}>
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <InputField
              error={Boolean(formik.touched.newPassword && formik.errors.newPassword)}
              autoFocus
              fullWidth
              helperText={formik.touched.newPassword && formik.errors.newPassword}
              label='New password'
              name='newPassword'
              onChange={formik.handleChange}
              value={formik.values.newPassword}
            />
          </Grid>
          <Grid item>
            <InputField
              error={Boolean(formik.touched.confirmPassword && formik.errors.confirmPassword)}
              fullWidth
              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              label='Confirm password'
              name='confirmPassword'
              type='password'
              onChange={formik.handleChange}
              value={formik.values.confirmPassword}
            />
          </Grid>
          <Grid item>
            <LoadingButton
              loading={formik.isSubmitting}
              variant='contained'
              color='primary'
              fullWidth
              onClick={formik.handleSubmit}
            >
              Reset Password
            </LoadingButton>
          </Grid>
        </Grid>
      </LoginLayout>
    </>
  );
};

export default ResetPassword;
