import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { Box, Button, Card, CardContent, Container, Typography } from '@mui/material';
import { CRPoliciesFilter } from '../../components/CRPolicies/CRPoliciesFilter';
import { Plus } from '../../assets/icons/plus';
import CRPoliciesAdd from '../../components/CRPoliciesAdd';
import CustomTable from '../../components/CustomTable';
import { useSelection } from '../../hooks/use-selection';
import { columns } from './columns.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { getCRPolicies, searchChangeCRPolicesAction } from '../../store/slices/listCRPolicies';
import { useNavigate } from 'react-router-dom';
import { styles } from '../ChangeRequests/styles';
import { clearFilters, onPageChange, onPageSizeChange, onSortChange, setFilters } from '../../store/slices/tableController';
import PermissionChecker from '../../components/PermissionChecker';

const CRPolicies = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const crPolicies = useSelector((state) => state.crPolicies);
  const controller = useSelector((state) => state.tableController);
  const [viewMappedPositionsData, setViewMappedPositionsData] = useReducer(
    (state, action) => ({
      ...state,
      ...action,
    }),
    {
      show: false,
      id: '',
    }
  );

  const [selectedPositions, handleSelect, handleSelectAll] = useSelection(crPolicies.crPolicies);

  const fetchData = useCallback(async (searched, filters, sort, sortBy, page, pageSize) => {
    if (searched) {
      const data = new FormData();
      if (filters.length) {
          // filters.forEach((filter) => data.append(filter.property, filter.value));
          data.append('c', filters[0].property);
          data.append('q', filters[0].value);
      }
      if (sortBy) {
        data.append('sort', sortBy);
        data.append('order', sort);
      }
      data.append('offset', page * pageSize);
      data.append('max', pageSize);
      dispatch(searchChangeCRPolicesAction(data));
    } else {
      const query = new URLSearchParams({});
      if (sortBy) {
        query.append('sort', sortBy);
        query.append('order', sort);
      }
      query.append('offset', page * pageSize);
      query.append('max', pageSize);
      dispatch(
        getCRPolicies({
          query,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (fetchData)
      fetchData(controller.searched, controller.filters, controller.sort, controller.sortBy, controller.page, controller.pageSize);
  }, [controller.searched, controller.filters, controller.sort, controller.sortBy, controller.page, controller.pageSize, fetchData]);

  const handleFiltersApply = (newFilters) => {
    const parsedFilters = newFilters.map((filter) => ({
      property: filter.property.name,
      value: filter.value,
      operator: filter.operator.value,
    }));

    dispatch(setFilters(parsedFilters));
  };

  const handleFiltersClear = () => {
    dispatch(clearFilters());
  };
  
  const handlePageChange = (newPage) => {
    dispatch(onPageChange(newPage - 1));
  };

  const handlePageSizeChange = (newPageSize) => {
    dispatch(onPageSizeChange(newPageSize));
  }

  const handleSortChange = (event, property) => {
    const isAsc = controller.sortBy === property && controller.sort === 'asc';
    dispatch(onSortChange({
      sort: isAsc ? 'desc' : 'asc',
      sortBy: property
    }));
  };

  const handleRowClick = (row) => {
    navigate(`/changerequest/crpolicies/view/${row}`);
  };

  return (
    <Box sx={styles.growBox}>
      <Container maxWidth='lg' sx={styles.pageWrapper}>
        <Box id='changeRequestHeader' sx={{ pt: '12px', pb: '32px' }}>
          <Box sx={styles.flexCenterNone}>
            <Typography variant='h4'>Change Policy</Typography>
            <Box sx={styles.growBox} />
            {/*<Grid item xs='auto'>
                <Button
                  sx={styles.mx(2)}
                  color='primary'
                  onClick={() => setOpenBulkUploadDialog(true)}
                  size='large'
                  startIcon={<UploadIcon fontSize='small' />}
                  variant='outlined'
                >
                  Bulk upload
                </Button>
                <PositionBulkUploadDialog
                  openBulkUploadDialog={openBulkUploadDialog}
                  onResult={handleBulkUploadResult}
                />
              </Grid>*/}
              <PermissionChecker
                requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_CREATE_CHANGE_REQUEST_POLICY']}
                noAuthRedirect={false}
              >
                <Button
                  color='primary'
                  onClick={() => navigate('/changerequest/crpolicies/add')}
                  size='large'
                  startIcon={<Plus fontSize='small' />}
                  variant='contained'
                >
                  Add
                </Button>
              </PermissionChecker>
          </Box>
        </Box>
        <Card sx={styles.dataWrappper}>
          <CardContent>
            <CRPoliciesFilter
              disabled={false}
              filters={controller.filters}
              onFiltersApply={handleFiltersApply}
              onFiltersClear={handleFiltersClear}
              selectedPositions={selectedPositions}
              filterProperties={columns}
              updatePolicyMappings={false}
            />
            <CustomTable
              isLoading={crPolicies.isLoading}
              onRowClick={handleRowClick}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              onSelect={handleSelect}
              onSelectAll={handleSelectAll}
              onSortChange={handleSortChange}
              page={controller.page + 1}
              columns={columns}
              rows={crPolicies.crPolicies}
              rowsCount={crPolicies.changeRequestPolicyCount}
              selectedRows={selectedPositions}
              sort={controller.sort}
              sortBy={controller.sortBy}
              showRowSelector={false}
              showPagignation
              keyColumn='id'
              route='changerequest/crpolicies'
              rowId={viewMappedPositionsData.id}
              action={() => fetchData(controller.searched, controller.filters, controller.sort, controller.sortBy, controller.page, controller.pageSize)}
            />
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default CRPolicies;
