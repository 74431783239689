import {
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';
import { styles } from './styles';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { LocationsMenu } from '../Locations/LocationsMenu';
import { ActionButton } from '../ActionsMenu/ActionButton';

const LocationData = ({ location, id }) => {
  const navigate = useNavigate();

  const details = {
    Name: location?.data?.name ?? '',
    Status: location?.data?.status ?? '',
  };

  return (
    <Grid container spacing={4}>
      <Grid item md={12} xs={12}>
        <Grid
          container
          spacing={3}
          sx={{
            mb: 5,
          }}
        >
          <Grid item xs={12}>
            <Box sx={styles.titleWrapper}>
              <Typography color='neutral.600' variant='subtitle2'>
                Location Details
              </Typography>
              <Box sx={styles.growBox} />
              <LocationsMenu Component={ActionButton} row={{id, ...location?.data}} handleAction={() => navigate('/settings/locations')}/>
            </Box>
          </Grid>
          {Object.keys(details).map((key) => (
            <Grid
              key={key}
              item
              xs={4}
              sx={{
                pt: '32px !important',
              }}
            >
              <Typography color='neutral.600' variant='body2'>
                {key}
              </Typography>
              {typeof details[key] === 'string' || !details[key] ? (
                <Typography
                  color='neutral.700'
                  variant='h6'
                  sx={{
                    pt: 2,
                  }}
                >
                  {details[key] || ''}
                </Typography>
              ) : (
                details[key]
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LocationData;
