import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { PositionStatusChip } from '../../components/Position/PositionStatusChip';
import { Status } from '../../components/Status';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import moment from 'moment';
import { PositionMenu } from '../../components/Position/PositionMenu';
import { ActionTableButton } from '../../components/ActionsMenu/ActionButton';

const columns = [
  {
    fieldName: 'designation',
    name: 'de_name',
    label: 'DESIGNATION',
    type: 'string',
    title: ({row,column}) => row[column.fieldName]?.name,
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row[column.fieldName]?.name}
      </Typography>
    ),
  },
  {
    fieldName: 'positionCode',
    name: 'c',
    label: 'POSITION CODE',
    type: 'number',
    title: ({row,column}) => row[column.fieldName],
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row[column.fieldName]}
      </Typography>
    ),
  },
  // {
  //   fieldName: 'title',
  //   name: 't',
  //   label: 'POSITION TITLE',
  //   type: 'string',
  //   title: ({row,column}) => row[column.fieldName],
  //   formatValue: ({ row, column }) => (
  //     <Typography variant='body2' noWrap>
  //       {row[column.fieldName]}
  //     </Typography>
  //   ),
  // },
  {
    fieldName: 'status',
    name: 's',
    label: 'STATUS',
    type: 'string',
    title: ({row,column}) => row[column.fieldName],
    formatValue: ({ row, column }) => <PositionStatusChip label={row[column.fieldName]} />,
  },
  // {
  //   fieldName: 'publicationStatus',
  //   name: 'publicationStatus',
  //   label: 'PUBLICATION STATUS',
  //   type: 'string',
  //   title: ({row,column}) => row[column.fieldName],
  //   filter: true,
  //   formatValue: ({ row, column }) => (
  //     <Status color={row[column.fieldName] === 'Unpublished' ? '#EFA12D' : '#27AB6E'} label={row[column.fieldName]} />
  //   ),
  // },
  {
    fieldName: 'canvasIntegrationStatus',
    name: 'canvasIntegrationStatus',
    label: 'CANVAS INT',
    type: 'string',
    title: ({row,column}) => row[column.fieldName],
    filter: true,
    formatValue: ({ row, column }) => {
      const backgroundColors = {
        'Not Integrated': '#0F9292',
        Pending: '#0F9292',
        'In Process': '#EFA12D',
        Successful: '#27AB6E',
        'Failed': '#EC4C47',
      };

      return <Status color={backgroundColors[row[column.fieldName]]} label={row[column.fieldName]} 
        icon={row[column.fieldName] === "Failed" 
        ? <InfoOutlinedIcon sx={{ color:  backgroundColors[row[column.fieldName]], fontSize: "16px", paddingLeft: "2px"}} titleAccess={row["integrationErrorMessage"]}/> 
        : null}/>
    },
  },
  {
    fieldName: 'changeRequestPolicy',
    name: 'changeRequestPolicy',
    label: 'CHANGE POLICY',
    type: 'string',
    filter: true,
    title: ({row,column}) => row[column.fieldName]?.name,
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row[column.fieldName]?.name}
      </Typography>
    ),
  },
  {
    fieldName: 'recruitmentPolicy',
    name: 'recruitmentPolicy',
    label: 'RECRUITMENT POLICY',
    type: 'string',
    filter: true,
    title: ({row,column}) => row[column.fieldName]?.name,
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row[column.fieldName]?.name}
      </Typography>
    ),
  },
  {
    fieldName: 'department',
    name: 'dp_name',
    label: 'DEPARTMENT',
    type: 'string',
    title: ({row,column}) => row[column.fieldName]?.name,
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row[column.fieldName]?.name}
      </Typography>
    ),
  },
  {
    fieldName: 'location',
    name: 'lo_name',
    label: 'LOCATION',
    type: 'string',
    title: ({row,column}) => row[column.fieldName]?.name,
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row[column.fieldName]?.name}
      </Typography>
    ),
  },
  {
    fieldName: 'filledOn',
    name: 'filledOn',
    label: 'FILLED DATE',
    type: 'string',
    title: ({row,column}) => row[column.fieldName]?.name,
    filter: true,
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row[column.fieldName]?.name}
      </Typography>
    ),
  },
  {
    fieldName: 'startDate',
    name: 'startDate',
    label: 'Position Start Date',
    type: 'string',
    title: ({row,column}) => row[column.fieldName] ? moment(row[column.fieldName]).format('MMM DD YYYY'): '',
    filter: true,
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row[column.fieldName] ? moment(row[column.fieldName]).format('MMM DD YYYY'): ''}
      </Typography>
    ),
  },
  {
    id: 'action',
    label: 'Actions',
    sticky: true,
    filter: true,
    formatValue: ({ row, action }) => (
      <PositionMenu Component={ActionTableButton} row={row} handleAction={action}/>
    ),
  },
];

export const useColumns = (position) => {
  const [columnState, setColumnState] = useState([]);
  //const customAttributes = useSelector((state) => state.customAttributes.data);

  const customAttributes = position?.extendedDef;

  const getCustomColumnValue = (customObject, fieldLabel) => {
    let returnValue = '';
    if (customObject?.customFieldValues?.length) {
      customObject.customFieldValues.every((item) => {
        if (item['fieldLabel'] === fieldLabel) {
          returnValue =
            item['fieldDataType'] === 'Date' ?
              (item['fieldValue'] === "" ?
                "" :
                moment(item['fieldValue']).format('MMM DD YYYY')) :
              item['fieldValue'];
          return false;
        }
        return true;
      });

      return returnValue;
    }

    return '';
  };

  useEffect(() => {
    let finalColumns = [...columns];
    const customColumns = customAttributes.metaCustomFieldDefinitions?.map((item) => {
      return {
        fieldName: item.fieldLabel,
        label: item.fieldLabel,
        name: item.fieldLabel,
        type: item.fieldDataType,
        filter: true,
        disableSorting: true,
        title: ({ row, column }) =>  getCustomColumnValue(row['customObjectInstance'], column.label),
        formatValue: ({ row, column }) => getCustomColumnValue(row['customObjectInstance'], column.label),
      };
    });
    if (customColumns) {
      const actionItem = finalColumns.pop();
      finalColumns = [...finalColumns, ...customColumns, actionItem];
    }
    setColumnState(finalColumns);
  }, [customAttributes]);
  return columnState;
};
