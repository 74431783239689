import { Chip, Divider, Grid, TextField, Typography } from '@mui/material'
import { styles } from './styles'
import { Box } from '@mui/system'

const CompanyData = ({ position }) => {
  const details = [
    {
      Section: 'Basic details',
      'Company name': position?.company?.customer?.customerName,
      Status: (
        <Chip
          label={position?.company?.customer?.customerStatus}
          size='small'
          sx={{
            backgroundColor: 'secondary.hover'
          }}
        />
      ),
      'No. of employees': position?.company?.customer?.numberOfEmployees,
      Description: (
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          disabled
          multiline
          name="description"
          rows={4}
          value={position?.company?.customer?.description}
        />
      ),
      'Created Date' : position?.company?.createdOn
    }, 
    {
      Section: 'Address',
      'Location name': position?.address?.locationName,
      'Address Line 1': position?.address?.addressLine1,
      'Address Line 2': position?.address?.addressLine2,
      'Address Line 3': position?.address?.addressLine3,
      'City': position?.address?.city,
      State: position?.address?.state,
      'Country': position?.address?.country,
      'Postal/Zip code': position?.address?.pinOrZip
    },
  ]

  return (
    <Grid container spacing={4}>
      <Grid item md={12} xs={12}>
        {details.map((detail, index) => (
          <>
            <Grid
              container
              spacing={3}
              sx={{
                mb: 5
              }}
            >
              <Grid item xs={12}>
                <Box sx={styles.titleWrapper}>
                  <Typography color='neutral.600' variant='subtitle2'>
                    {detail.Section}
                  </Typography>
                </Box>
              </Grid>
              {Object.keys(detail).filter((_, key) => key).map((key) => (
                <Grid
                  item
                  xs={key === 'Description' ? 12 : 4}
                  sx={{
                    pt: '32px !important'
                  }}
                >
                  <Typography color='neutral.600' variant='body2'>
                    {key}
                  </Typography>
                  {typeof detail[key] === 'string' || !detail[key] ? (
                    <Typography
                      color='neutral.700'
                      variant='h6'
                      sx={{
                        pt: 2
                      }}
                    >
                      {detail[key] || '-'}
                    </Typography>
                  ) : (
                    detail[key]
                  )}
                </Grid>
              ))}
            </Grid>
            {(index !== details.length - 1) && <Divider />}
          </>
        ))}
      </Grid>
    </Grid>
  )
}

export default CompanyData
