import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import LocationBulkActionMenu from './LocationBulkActionMenu';
import { FilterDialog } from '../FilterDialog';

export const LocationsFilter = (props) => {
  const {
    filters,
    onFiltersApply,
    onFiltersClear,
    selectedLocations,
    filterProperties,
    onBulkStatusChangeConfirmation,
    initialFilters
  } = props;

  const [openFilterDialog, setOpenFilterDialog] = useState(false);

  return (
    <>
      <Grid container spacing={1} direction='row-reverse' justifyContent='flex-start' alignItems='center' mb={2}>
        {selectedLocations.length > 0 && (
          <Grid item alignContent='flex-end' xs='auto'>
            <LocationBulkActionMenu
              disabled={false}
              selectedPositions={selectedLocations}
              selectedCount={selectedLocations.length}
              onBulkStatusChangeConfirmation={onBulkStatusChangeConfirmation}
            />
          </Grid>
        )}
        <Grid item alignContent='flex-end' xs='auto'>
          <Button
            color='primary'
            disabled={false}
            onClick={() => setOpenFilterDialog(true)}
            startIcon={<FilterAltIcon />}
            size='medium'
            variant={filters.length ? 'contained' : 'text'}
          >
            Filter
          </Button>
          <FilterDialog
            onApply={onFiltersApply}
            onClear={onFiltersClear}
            onClose={() => setOpenFilterDialog(false)}
            open={openFilterDialog}
            operators={[]}
            properties={filterProperties}
            initialFilters={initialFilters}
          />
        </Grid>
        {/*
        <Grid item alignContent='flex-end' xs='auto'>
          <Button
            color='secondary'
            disabled={false}
            onClick={() => console.log(true)}
            size='medium'
            variant='text'
            startIcon={<ExportIcon fontSize='small' />}
          >
            Export
          </Button>
        </Grid>*/}
      </Grid>
    </>
  );
};

LocationsFilter.defaultProps = {
  filters: [],
  selectedUsers: [],
  view: 'all',
  actionItems: <></>,
};

LocationsFilter.propTypes = {
  disabled: PropTypes.bool,
  filters: PropTypes.array,
  onFiltersApply: PropTypes.func,
  onFiltersClear: PropTypes.func,
  onQueryChange: PropTypes.func,
  onViewChange: PropTypes.func,
  query: PropTypes.string,
  selectedUsers: PropTypes.array,
  view: PropTypes.string,
  actionItems: PropTypes.element,
};
