import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomTable from '../../components/CustomTable';
import { api } from '../../axios';
import { columns } from './columns';
import { useDispatch, useSelector } from 'react-redux';
import PermissionChecker from '../../components/PermissionChecker';
import { styles } from './styles';
import AddIcon from '@mui/icons-material/Add';
import { updateAllSelectedRoles, updateRoles, updateSelectedRoles } from '../../store/slices/roles';
import { onPageChange, onPageSizeChange, onSortChange } from '../../store/slices/tableController';

const Roles = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { roles, selectedRoles } = useSelector((state) => state.roles);
  const controller = useSelector((state) => state.tableController);

  const fetchData = useCallback(async (searched, sort, sortBy, page, pageSize) => {
    setLoading(true);
    try {
      if (searched) {
        const query = new URLSearchParams({});
        if (sortBy) {
          query.append('sort', sortBy);
          query.append('order', sort);
        }
        query.append('offset', page * pageSize);
        query.append('max', pageSize);
        const response = await api.post(`/searchRole?${query.toString()}`);
        if (response.data) {
          dispatch(updateRoles(response.data));
        }
      } else {
        const query = new URLSearchParams({});
        if (sortBy) {
          query.append('sort', sortBy);
          query.append('order', sort);
        }
        query.append('offset', page * pageSize);
        query.append('max', pageSize);
        const response = await api.post(`/listRoles?${query.toString()}`);
        if (response.data) {
          dispatch(updateRoles(response.data));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (fetchData)
      fetchData(controller.searched, controller.sort, controller.sortBy, controller.page, controller.pageSize);
  }, [controller.searched, controller.sort, controller.sortBy, controller.page, controller.pageSize, fetchData]);

  const handlePageChange = (newPage) => {
    dispatch(onPageChange(newPage - 1));
  };

  const handlePageSizeChange = (newPageSize) => {
    dispatch(onPageSizeChange(newPageSize));
  }

  const handleSortChange = (event, property) => {
    const isAsc = controller.sortBy === property && controller.sort === 'asc';
    dispatch(onSortChange({
      sort: isAsc ? 'desc' : 'asc',
      sortBy: property
    }));
  };

  const handleSelect = (event, rowId) => {
    dispatch(updateSelectedRoles({ isChecked: event.target.checked, rowId }));
  };

  const handleSelectAll = (event) => {
    dispatch(updateAllSelectedRoles({ isChecked: event.target.checked }));
  };

  const handleRowClick = (id) => {
    navigate(`/settings/roles/${id}`);
  };

  return (
    <Box sx={styles.growBox}>
      <Container maxWidth='lg' sx={styles.pageWrapper}>
        <Grid container direction='column' spacing={0}>
          <Grid
            sx={{ pt: '12px', pb: '32px' }}
            item
            container
            spacing={1}
            justifyContent='flex-start'
            alignItems='center'
            py={4}
          >
            <Grid item>
              <Typography variant='h4'>Roles</Typography>
            </Grid>
            <Grid item xs></Grid>
            <Grid item xs='auto'>
              <PermissionChecker requiredPermissions={['ROLE_BASE_PERMISSION_SECROLE_CREATE']} noAuthRedirect={false}>
                <Button
                  color='primary'
                  onClick={() => navigate('/settings/roles/add')}
                  size='large'
                  startIcon={<AddIcon fontSize='small' />}
                  variant='contained'
                  sx={{ ':hover': { backgroundColor: 'secondary.main' } }}
                >
                  Add Role
                </Button>
              </PermissionChecker>
            </Grid>
          </Grid>
          <Grid item sx={{ pt: '0px !important' }}>
            <Card>
              <CardContent>
                <CustomTable
                  isLoading={loading}
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                  onSelect={handleSelect}
                  onSelectAll={handleSelectAll}
                  onRowClick={handleRowClick}
                  onSortChange={handleSortChange}
                  page={controller.page + 1}
                  columns={columns}
                  rows={roles.data}
                  rowsCount={roles.count}
                  selectedRows={selectedRoles}
                  sort={controller.sort}
                  sortBy={controller.sortBy}
                  showRowSelector={false}
                  showPagignation={true}
                  keyColumn='id'
                  action={() =>  fetchData(controller.searched, controller.sort, controller.sortBy, controller.page, controller.pageSize)}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Roles;
