import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, Container, Typography, Grid, Skeleton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../../../components/CustomTable';
import { useSelection } from '../../../hooks/use-selection';
import { CRPoliciesFilter } from '../../../components/CRPolicies/CRPoliciesFilter';
import {
  bulkUpdatePositionChangeRequestPolicyMappings,
  getPositionList,
  getPositionsMappedToChangeRequestPolicy,
} from '../../../store/slices/listCRPolicies';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { styles } from '../styles';
import { useColumns } from '../columnsMappedPosition.jsx';
import { useColumns as usePositionsColumns } from '../columnsPositions';
import FormData from 'form-data';

const ViewUpdateMappedPositions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const { crPolicyId } = params
  const isUpdateMode = location.pathname.includes('update');
  const { crPolicies, viewPolicy, positionsMapped, listPositions, isLoading } = useSelector((state) => state.crPolicies);
  const [updatePolicyMappings, setUpdatePolicyMappings] = useState(isUpdateMode);
  const mappedPositionColumns = useColumns();
  const positionsColumns = usePositionsColumns();
  const [mappedPositions, setMappedPositions] = useState([]);
  const [selectedPositions, handleSelect, handleSelectAll, handleClearSelected] = useSelection(listPositions);

  const { enqueueSnackbar } = useSnackbar();

  const [controller, setController] = useState({
    filters: [],
    page: 0,
    sort: 'desc',
    sortBy: null,
    view: 'all',
    searched: false,
    pageSize: 10,
  });

  useEffect(() => {
    dispatch(getPositionsMappedToChangeRequestPolicy({ id: crPolicyId }));
  }, [crPolicyId]);

  useEffect(() => {
    const mappedItem = positionsMapped?.mapped?.filter((item) => item.policy.id === Number(crPolicyId));
    setMappedPositions(mappedItem);
  }, [positionsMapped.mapped]);

  useEffect(() => {
    if (updatePolicyMappings) {
      const query = new URLSearchParams({});
      query.append('all', true);
      dispatch(
        getPositionList({
          query,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePolicyMappings]);

  useEffect(() => {
    if (updatePolicyMappings) {
      const event = { target: { checked: true } };
      mappedPositions.forEach((item) => {
        handleSelect(event, item.position.id);
      });
    }
  }, [updatePolicyMappings, positionsMapped.mapped]);

  const handleFiltersApply = (newFilters) => {
    const parsedFilters = newFilters.map((filter) => ({
      property: filter.property.name,
      value: filter.value,
      operator: filter.operator.value,
    }));

    setController({
      ...controller,
      page: 0,
      filters: parsedFilters,
      searched: true,
    });
  };
  const handleFiltersClear = () => {
    setController({
      ...controller,
      page: 0,
      filters: [],
      searched: false,
    });
  };
  const handlePageChange = (newPage) => {
    setController({
      ...controller,
      page: newPage - 1,
    });
  };

  const handlePageSizeChange = (newPageSize) => {
    setController({
      ...controller,
      page: 0,
      pageSize: newPageSize
    });
  }

  const handleSortChange = (event, property) => {
    const isAsc = controller.sortBy === property && controller.sort === 'asc';

    setController({
      ...controller,
      page: 0,
      sort: isAsc ? 'desc' : 'asc',
      sortBy: property,
    });
  };

  const handelBulkUpdate = () => {
    if (!selectedPositions.length) {
      setUpdatePolicyMappings(true);
      return;
    }

    const bodyFormData = new FormData();
    bodyFormData.append('changeRequestPolicyId', Number(crPolicyId));
    bodyFormData.append('positionIds', selectedPositions.join());

    dispatch(bulkUpdatePositionChangeRequestPolicyMappings(bodyFormData)).then((res) => {
      if (res.payload.status !== 'SUCCESS') {
        enqueueSnackbar(' Something is wrong', { variant: 'error' });
        return;
      }
      dispatch(getPositionsMappedToChangeRequestPolicy({ id: crPolicyId }));
      enqueueSnackbar(' Position mappings successfully updated', { variant: 'success' });
      handleClearSelected();
      setUpdatePolicyMappings(false);
    });
  };

  const renderMapViewPosition = () => {
    const crPolicy = crPolicies.find((element) => element.id === Number(crPolicyId)) || viewPolicy?.positionChangeRequestPolicy;
    return (
      <Box sx={styles.mappedBox}>
        <Typography sx={styles.contentText}>{`Policy Name: ${crPolicy?.name}`}</Typography>
        <Typography sx={styles.contentText}>{`Mapped Positions: ${
          positionsMapped.count || 0
        }`}</Typography>
      </Box>
    );
  };

  return (
    <Box sx={styles.growBox}>
      <Container maxWidth='lg' sx={styles.pageWrapper}>
        <Grid container direction='column' spacing={2}>
          <Grid
            sx={{ py: '32px !important' }}
            item
            container
            spacing={1}
            justifyContent='flex-start'
            alignItems='center'
          >
            <Grid item>
              <Typography variant='h4'>Change Policy</Typography>
            </Grid>
          </Grid>
          <Grid item sx={{ pt: '0px !important' }}>
            <Card>
              <CardContent>
                <Grid container direction='column' spacing={2}>
                  <Grid item>
                    <Button
                      startIcon={<ChevronLeft />}
                      variant='text'
                      color='secondary'
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid item>
                    {isLoading ? (
                      <Box sx={{ width: '100%' }}>
                        <Skeleton />
                        <Skeleton />
                      </Box>
                    ) : (
                      <>{renderMapViewPosition()}</>
                    )}
                  </Grid>
                  <Grid item>
                    <CRPoliciesFilter
                      disabled={false}
                      onFiltersApply={handleFiltersApply}
                      onFiltersClear={handleFiltersClear}
                      selectedPositions={selectedPositions}
                      filterProperties={updatePolicyMappings ? positionsColumns : mappedPositionColumns}
                      viewMappedPositionsShow={true}
                      updatePolicyMappings={updatePolicyMappings}
                      handelBulkUpdate={handelBulkUpdate}
                    />
                    <CustomTable
                      isLoading={isLoading}
                      onPageChange={handlePageChange}
                      onPageSizeChange={handlePageSizeChange}
                      onSelect={handleSelect}
                      onSelectAll={handleSelectAll}
                      onSortChange={handleSortChange}
                      page={controller.page + 1}
                      columns={updatePolicyMappings ? positionsColumns : mappedPositionColumns}
                      rows={updatePolicyMappings ? listPositions : mappedPositions}
                      rowsCount={updatePolicyMappings ? listPositions.length : mappedPositions.length}
                      selectedRows={selectedPositions}
                      sort={controller.sort}
                      sortBy={controller.sortBy}
                      showRowSelector={updatePolicyMappings}
                      showPagignation={!updatePolicyMappings}
                      keyColumn='id'
                      route='changerequest/crpolicies'
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ViewUpdateMappedPositions;
