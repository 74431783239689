import React, { useEffect, useReducer } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Grid, Typography, Button, FormHelperText } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { loginApi, api } from '../../axios';
import LoginLayout from '../../components/LoginLayout';
import { InputField } from '../../components/InputField';
import { updateUser } from '../../store/slices/session';
import { useSnackbar } from 'notistack';
import { hasPermission } from '../../utils/permission';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.session);
  const isSuperAdmin = hasPermission(user?.authorities, ['ROLE_SUPER_ADMINISTRATOR']);

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup
        .string()
        .transform(value => value?.trim())
        .max(255)
        .required('Username is required'),
      password: Yup
        .string()
        .transform(value => value?.trim())
        .max(255)
        .required('Password is required')
    }),
    onSubmit: async (values, helpers) => {
      helpers.setSubmitting(true);
      try {
        const data = JSON.stringify({ username: values.username?.trim(), password: values.password?.trim() });
        const loginResponse = await loginApi.post('/login', data);
        if (loginResponse.data) {
          localStorage.setItem('token', loginResponse.data.access_token);
          const currentUser = await api.post('/getCurrentUser');
          if (currentUser.status === 200 && currentUser.data) {
            enqueueSnackbar('Login Successful', { variant: 'success' });
            dispatch(updateUser(currentUser.data));
            isSuperAdmin ? navigate('/company') : navigate('/dashboard');
          } else {
            dispatch(updateUser(null));
            navigate('/login');
          }
        }
      } catch (error) {
        helpers.setErrors({ submit: "Invalid Username or Password" });
        enqueueSnackbar(error.message, { variant: 'error' });
      } finally {
        helpers.setSubmitting(false);
      }
    }
  });

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (user.userDBId) {
      isSuperAdmin ? navigate('/company') : navigate('/dashboard');
    }
  }, [user.userDBId, navigate]);

  return (
    <>
      <LoginLayout>
        <Grid container spacing={2} direction='column'>
          <Grid item>
            <Typography variant='h2' color='textPrimary'>
              Hello Again!
            </Typography>
          </Grid>
          <Grid item>
            <InputField
              autoFocus
              fullWidth
              label='Username'
              name='username'
              onChange={formik.handleChange}
              value={formik.values.username}
              error={Boolean(formik.touched.username && formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
            />
          </Grid>
          <Grid item>
            <InputField
              fullWidth
              label='Password'
              name='password'
              type='password'
              onChange={formik.handleChange}
              value={formik.values.password}
              error={Boolean(formik.touched.password && formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          {formik.errors.submit && (
            <Grid item>
              <FormHelperText error>
                {formik.errors.submit}
              </FormHelperText>
            </Grid>
          )}
          <Grid item alignSelf={'flex-end'}>
            <Button variant='text' sx={{ color: 'secondary.main' }} onClick={() => navigate('/forgot-password')}>
              Forgot Password?
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              loading={formik.isSubmitting}
              variant='contained'
              color='primary'
              fullWidth
              onClick={formik.handleSubmit}
            >
              Login
            </LoadingButton>
          </Grid>
        </Grid>
      </LoginLayout>
    </>
  );
};

export default Login;
