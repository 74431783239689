import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { styles } from "./styles";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined';
import ThumbUpOffAltOutlinedIcon from '@mui/icons-material/ThumbUpOffAltOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { TabContext, TabPanel } from "@mui/lab";
import { styles as positionStyles } from '../AddPosition/styles'
import AllChangeRequests from "./AllRequests";
import ApproverChangeRequests from "./ApproverChangeRequests";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { resetController, setController, setInitialFilters } from "../../store/slices/tableController";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import StatusCard from "../../components/common/StatusCard";

const views = [
  {
    label: 'Change Requests',
    value: 'change-requests',
  },
  {
    label: 'Approval Requests',
    value: 'approval-requests',
  }
];

const icons = {
  "Active": CheckCircleOutlineIcon,
  "Approved": ThumbUpOffAltOutlinedIcon,
  "Rejected": DoNotDisturbAltOutlinedIcon,
  "Cancelled": CloseOutlinedIcon,
}

const ChangeRequests = () => {
  // TODO: to be replaced with approver role
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const urlType = searchParams && searchParams.get("type")
  const [view, setView] = useState(urlType || 'change-requests');
  const { changeRequests } = useSelector((state) => state.changeRequests);
  const controller = useSelector((state) => state.tableController);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setInitialFilters([{
      property: {
        name: 'p',
        type: 'string',
      },
      operator: {
        value: '',
        typesAccepted: [],
      },
      value: "",
    }]))
  },[])

  const handleTabNavigation = (value) => {
    setView(value)
    navigate(`?type=${value}`)
  }

  useEffect(() => {
    handleStatusFilter();
  },[view])

  const handleStatusFilter = (value) => {
    if(!value) 
      return dispatch(resetController({
        initialFilters: [{
          property: {
            name: 'p',
            type: 'string',
          },
          operator: {
            value: '',
            typesAccepted: [],
          },
          value: "",
        }]
      }))
   else dispatch(setController({
      page: 0,
      filters: [{
        operator: "",
        property: "s",
        value: value
      }],
      initialFilters: [{
        property: {
          name: 's',
          type: 'string',
        },
        operator: {
          value: '',
          typesAccepted: [],
        },
        value: value,
      }],
      searched: true
    }))
  }

  return (
    <Box sx={styles.growBox}>
      <Container maxWidth="lg" sx={styles.pageWrapper}>
        <Box id="changeRequestHeader" sx={{ pt: '12px !important', pb: '32px' }}>
          <Box sx={styles.flexCenterNone}>
            <Typography variant="h4">Change Requests</Typography>
            <Box sx={styles.growBox} />
          </Box>
        </Box>
        <Grid
          sx={{ pt: '0 !important', pb: '32px' }}
          item
          container
          spacing={3}
          justifyContent='flex-start'
          alignItems='stretch'
          columns={12}
        >
        {(["Active", "Approved", "Rejected", "Cancelled"]).map((item) => {
              const filteredStatus = controller.filters?.find(item => item.property === "s")?.value;
              return (
                <Grid item xs={3} sm={3} md={3} lg={3}>
                  <StatusCard Icon={icons[item]} label={item} filteredStatus={filteredStatus} value={changeRequests?.statusWiseStats?.[item]} handleStatusFilter={view === "change-requests" && handleStatusFilter}/>
                </Grid>
              );
            })}
        </Grid>
        <Card sx={styles.dataWrappper}>
          <CardContent>
            <TabContext value={view}>
              <Box>
                <Tabs
                  value={view}
                  onChange={(event, value) => handleTabNavigation(value)}
                  aria-label='basic tabs example'
                >
                  {views.map((option) => (
                    <Tab key={option.label} disabled={false} label={option.label} value={option.value} />
                  ))}
                </Tabs>
                <Divider />
              </Box>
              <TabPanel sx={{ ...positionStyles.tabpanel, pt: 0 }} value={'change-requests'}>
                <AllChangeRequests />
              </TabPanel>
              <TabPanel sx={{ ...positionStyles.tabpanel, pt: 0 }} value={'approval-requests'}>
                <ApproverChangeRequests />
              </TabPanel>
            </TabContext>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default ChangeRequests;
