import React from 'react';
import { FormHelperText, Grid, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import FormData from 'form-data';
import LoginLayout from '../../components/LoginLayout';
import { InputField } from '../../components/InputField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';

const ForgotPassword = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      username: '',
    },
    validationSchema: Yup.object({
      username: Yup
        .string()
        .transform(value => value?.trim())
        .max(255)
        .required('Username is required'),
    }),
    onSubmit: async (values, helpers) => {
      helpers.setSubmitting(true);
      try {
        const data = new FormData();
        data.append('u', values.username?.trim());
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/restAuthApi/forgotPasswordAction`, data); //'http://135.181.205.50:8080/restAuthApi/forgotPasswordAction', data);
        if (response.data?.status === 'SUCCESS') {
          enqueueSnackbar(response.data.message, { variant: 'success' });
        } else {
          enqueueSnackbar(response.data.message, { variant: 'error' });
        }
      } catch (error) {
        helpers.setErrors({ submit: "Invalid Username" });
        enqueueSnackbar(error.message, { variant: 'error' });
      } finally {
        helpers.setSubmitting(false);
      }
    }
  });
  
  return (
    <>
      <LoginLayout>
        <Grid container spacing={2} direction='column'>
          <Grid item>
            <IconButton color='primary' onClick={() => navigate('/login')}>
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid container direction='column'>
            <Grid item>
              <InputField
                autoFocus
                fullWidth
                label='Username'
                name='username'
                onChange={formik.handleChange}
                value={formik.values.username}
                error={Boolean(formik.touched.username && formik.errors.username)}
                helperText={formik.touched.username && formik.errors.username}
              />
            </Grid>
            {formik.errors.submit && (
              <Grid item>
                <FormHelperText error>
                  {formik.errors.submit}
                </FormHelperText>
              </Grid>
            )}
            <Grid item>
              <LoadingButton
                loading={formik.isSubmitting}
                variant='contained'
                color='primary'
                type='submit'
                fullWidth
              >
                Reset Password
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </LoginLayout>
    </>
  );
};

export default ForgotPassword;
