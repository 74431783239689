import React, { useEffect, useMemo, useState } from 'react'
import { Card, CardContent, CardHeader, Divider, } from '@mui/material'
import Chart from 'react-apexcharts'
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { getPositionSummaryByStatus } from '../../store/slices/dashboard';


const PositionStatusGraph = (props) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [range, setRange] = useState('Last 7 days');
  const { positionSummaryByStatus } = useSelector((state) => state.dashboard);
  const data = {
    series: [
      {
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        name: 'Count',
      },
    ],
    categories: [
      "Draft",
      'Open Pending Intake',
      'Open Active Sourcing',
      'On Hold - HM Delay',
      "Offer Negotiation",
      "Offer Released",
      "Offer Accepted",
      "Filled",
      "On Hold",
      "Cancelled",
    ],
  };
  const [chartData, setChartData] = useState(data)
  useEffect(() => {
    if (positionSummaryByStatus?.data?.statusWiseStats) {
      const stats = data?.categories.map(cat => positionSummaryByStatus?.data?.statusWiseStats[cat] || 0)
      data.series[0].data = stats
      setChartData(data)
    }
  }, [positionSummaryByStatus])

  useEffect(() => {
    dispatch(getPositionSummaryByStatus())
  }, [])

  const chartOptions =  useMemo(() => ({
    chart: {
      background: 'transparent',
      toolbar: {
        show: false,
      },
    },
    colors: ['#187B96', '#215263'],
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top',
        }
      }
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ["#3F455A"]
      }
    },
    grid: {
      borderColor: theme.palette.divider,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      markers: {
        radius: 50,
      },
    },
    states: {
      active: {
        filter: {
          type: 'none',
        },
      },
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
    stroke: {
      show: false,
    },
    theme: {
      mode: theme.palette.mode,
    },
    tooltip: {
      theme: theme.palette.mode,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: chartData.categories,
      labels: {
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
    yaxis: {
      labels: {
        offsetX: -12,
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
  }), [chartData]);

  const ranges = [
    {
      label: 'Last 7 days',
      onClick: () => {
        setRange('Last 7 days');
      },
    },
    {
      label: 'Last Month',
      onClick: () => {
        setRange('Last Month');
      },
    },
    {
      label: 'Last Year',
      onClick: () => {
        setRange('Last Year');
      },
    },
  ];

  return (
    <Card {...props}>
      <CardHeader
        // action={<ActionsMenu actions={ranges} label={range} size='small' variant='text' />}
        title='Positions by Status'
      />
      <Divider />
      <CardContent>
        <Chart height={400} options={chartOptions} series={chartData.series} type='bar' />
      </CardContent>
    </Card>
  );
};

export default PositionStatusGraph;
