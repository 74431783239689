import React from 'react';
import { Typography, Chip } from '@mui/material';
import { CRPoliciesMenu } from '../../components/CRPolicies/CRPoliciesMenu';
import { ActionTableButton } from '../../components/ActionsMenu/ActionButton';

const backgroundColors = {
  Inactive: '#FFE1D3',
  Active: '#D6F5C7',
};

export const columns = [
  {
    fieldName: 'name',
    name: 'n',
    label: 'NAME',
    type: 'string',
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row[column.fieldName]}
      </Typography>
    ),
  },
  {
    fieldName: 'status',
    name: 's',
    label: 'STATUS',
    type: 'string',
    formatValue: ({ row, column }) => (
      <Chip label={row[column.fieldName]} sx={{ backgroundColor: backgroundColors[row[column.fieldName]] }} />
    ),
  },
  {
    fieldName: 'approvers',
    label: 'NO. OF MINOR APPROVERS',
    type: 'string',
    filter: true,
    formatValue: ({ row, column }) => {
      const approvers = row[column.fieldName];
      const totalCount = approvers?.filter(item => item.categoryType?.name === "Minor").length;
      return (
        <Typography variant='body2' noWrap>
          {totalCount}
        </Typography>
    )},
  },
  {
    fieldName: 'approvers',
    label: 'NO. OF MAJOR APPROVERS',
    type: 'string',
    filter: true,
    formatValue: ({ row, column }) => {
      const approvers = row[column.fieldName];
      const totalCount = approvers?.filter(item => item.categoryType?.name === "Major").length;
      return (
        <Typography variant='body2' noWrap>
          {totalCount}
        </Typography>
    )},
  },
  {
    fieldName: 'positionsMapped',
    label: 'NO. OF MAPPED POSITIONS',
    type: 'string',
    filter: false,
    formatValue: ({ row, column }) => {
      return (
        <Typography variant='body2' noWrap>
          { row[column.fieldName]}
        </Typography>
    )},
  },
  {
    id: 'action',
    label: 'Actions',
    sticky: true,
    filter: true,
    formatValue: ({ row, action }) => (
      <CRPoliciesMenu row={row} Component={ActionTableButton} handleAction={action} />
    ),
  },
];
