import { Chip, Typography } from '@mui/material';
import { RecruitmentPolicyMenu } from './RecruitmentPolicyMenu';
import { ActionTableButton } from '../../components/ActionsMenu/ActionButton';

export const PolicyStatusColor = (status) => {
  switch(status){
    case "Active": return '#D6F5C7';
    case "Inactive": return '#FFF2CC';
    case "Deleted": return "#FFE1D3";
    default: return "D6F5C7"
  }
}

export const columns = [
  {
    fieldName: 'name',
    name: 'n',
    label: 'NAME',
    type: 'string',
    disableSorting: true,
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row[column.fieldName]}
      </Typography>
    ),
  },
  {
    fieldName: 'rulesCount',
    name: 'r',
    label: 'No. of Rules',
    type: 'string',
    disableSorting: true,
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row[column.fieldName]}
      </Typography>
    ),
  },
  {
    fieldName: 'mappedPositionsCount',
    name: 'p',
    label: 'No. of Mapped Positions',
    type: 'string',
    disableSorting: true,
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row[column.fieldName]}
      </Typography>
    ),
  },
  {
    fieldName: 'statusOnHQ',
    name: 's',
    label: 'STATUS',
    type: 'string',
    disableSorting: true,
    formatValue: ({ row, column }) => (
      <Chip label={ row[column.fieldName]} 
        sx={{ backgroundColor: PolicyStatusColor(row[column.fieldName]) }} />
    ),
  },
  {
    id: 'action',
    label: 'Actions',
    sticky: true,
    filter: true,
    disableSorting: true,
    formatValue: ({ row, action }) => (
      <RecruitmentPolicyMenu Component={ActionTableButton} page="list" row={row} handleAction={action} />
    ),
  },
];
