import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Grid,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { formDataApi } from "../../../axios";
import { getChangeRequests } from "../../../store/slices/listChangeRequests";
import { ChevronLeft as ChevronLeftIcon } from '../../../assets/icons/chevron-left'
import UpdateChangeRequests from "./updateRequestDetails";
import { styles } from "../ModifyChangeRequest/styles";
import { hasPermission } from "../../../utils/permission";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import minorCategoryIcon from '../../../assets/images/minorCategory.svg';
import majorCategoryIcon from '../../../assets/images/majorCategory.svg';
import { PositionStatusChip } from "../../../components/Position/PositionStatusChip";
import { StatusBackgroundColors } from "../columns";
import { ActionButton } from "../../../components/ActionsMenu/ActionButton";
import { ChangeRequestMenu } from "../../../components/ChangeRequestMenu";

export const ChangeRequestDetails = (changeRequest) => {
  const positionStatus = useMemo(() => {
    const statusAttributes = changeRequest?.changeRequest?.attributes?.find(item => item.name === "status");
    if(statusAttributes){
      return statusAttributes?.currentValue;
    }
    return changeRequest?.changeRequest?.position?.status
  }, [ changeRequest?.changeRequest ])

  return <Grid>
    <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
      <Grid item container spacing={2} justifyContent="center">
        <Grid item xs={3} display={'grid'}>
          <Typography sx={styles.text} color='textSecondary' variant='subtitle2'>
            Position Code:
          </Typography>
          <Typography sx={styles.text} color='textPrimary' variant='body2'>
            {changeRequest?.changeRequest?.position?.positionCode}
          </Typography>
        </Grid>
        <Grid item xs={3}  display={'grid'}>
          <Typography sx={styles.text} color="textSecondary" variant="subtitle2">
            Position Status:
          </Typography>
          <Box>{positionStatus ? <PositionStatusChip label={positionStatus} /> : '-'}</Box>
        </Grid>
        <Grid item xs={3}  display={'grid'}>
          <Typography sx={styles.text} color="textSecondary" variant="subtitle2">
            Change Policy Name:
          </Typography>
          <Typography sx={styles.text} color="textPrimary" variant="body2">
            {changeRequest?.changeRequest?.position?.changeRequestPolicy?.name || '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}  display={'grid'}>
          <Typography sx={styles.text} color="textSecondary" variant="subtitle2">
            Category:
          </Typography>
          <Typography sx={styles.text} color="textPrimary" variant="body2">
          <img src={changeRequest?.changeRequest?.categoryType?.name === "Minor" ? minorCategoryIcon : majorCategoryIcon} alt={changeRequest?.changeRequest?.categoryType?.name} style={{paddingRight: "6px"}} />
          {changeRequest?.changeRequest?.categoryType?.name}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container spacing={2} justifyContent="center">
        <Grid item xs={3}  display={'grid'}>
          <Typography sx={styles.text} color="textSecondary" variant="subtitle2">
            Change request Status:
          </Typography>
          <Box><Chip label={changeRequest?.changeRequest?.status} sx={{ backgroundColor: StatusBackgroundColors[changeRequest?.changeRequest?.status?.toLowerCase()] }} /></Box>
        </Grid>
        <Grid item xs={3}  display={'grid'}>
          <Typography sx={styles.text} color="textSecondary" variant="subtitle2">
            Request Submitted By:
          </Typography>
          <Typography sx={styles.text} color="textPrimary" variant="body2">
            {changeRequest?.changeRequest?.createdBy?.employee.fullName  ? changeRequest?.changeRequest?.createdBy?.employee.fullName : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}  display={'grid'}>
          <Typography sx={styles.text} color="textSecondary" variant="subtitle2">
            Request Submitted On:
          </Typography>
          <Typography sx={styles.text} color="textPrimary" variant="body2">
            {changeRequest?.changeRequest?.createdOn  ? moment(changeRequest?.changeRequest?.createdOn).format('MMM DD YYYY') : '-'}
          </Typography>
        </Grid>
        <Grid item xs={3}  display={'grid'}/>
      </Grid>
    </Grid>
    <Grid item xs={12}>
      <UpdateChangeRequests
        changeRequest={changeRequest}
      />
    </Grid>
    <Grid item sx={{ pt: 2 }} xs={12}>
      <TextField
        fullWidth
        label="Comment"
        size="small"
        variant="outlined"
        value={changeRequest?.changeRequest?.comments}
        disabled
        multiline
        rows={5}
      />
    </Grid>
  </Grid>
}

const ViewChangeRequests = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [hasApproverRole, setHasApproverRole] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const [isApproverCR, setIsApproverCR] = useState(false)
  const currentUser = useSelector((state) => state.session.user);
  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasCompaDisplayPermisson = hasPermission(authorities, ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_COMPA_VIEW_CHANGE_REQUEST'])

  useEffect(() => {
    if (searchParams){
      if(searchParams.get("type"))
        searchParams.get("type") === "approver" && setIsApproverCR(true)
      else setSearchParams({type: "all"})
    }
  }, [searchParams])

  useEffect(() => {
    dispatch(getChangeRequests())
  }, []);

  const [changeRequest, setChangeRequest] = useState({ isLoading: true });

  useEffect(() => {
    if (searchParams && searchParams.get("type")) {
      setChangeRequest({ ...changeRequest, isLoading: true })
      const getCRDetails = async () => {
        const bodyFormData = new FormData()
        bodyFormData.append('id', params.id)
        const apiPath = isApproverCR || userHasCompaDisplayPermisson ? '/displayChangeRequestWithCompaFields' : '/displayChangeRequest'
        const response = await formDataApi.post(apiPath, bodyFormData);
        if (response.data)  setChangeRequest(response.data);
      };
      getCRDetails();
    }
  }, [userHasCompaDisplayPermisson, isApproverCR, searchParams]);

  useEffect(() => {
    const approvers = changeRequest?.changeRequest?.position?.changeRequestPolicy?.approvers;
    const currentCRCategory = changeRequest?.changeRequest?.categoryType?.name;
    if (currentUser?.empDBID && currentCRCategory && Array.isArray(approvers) && approvers.length > 0 ) {
      const currentCRCategoryApprover = approvers.find(item => (item?.categoryType?.name === currentCRCategory) && (parseInt(currentUser?.empDBID) === item?.employeeId))
      if(currentCRCategoryApprover)  setHasApproverRole(true)
    }
  }, [currentUser?.empDBID, changeRequest?.changeRequest?.position?.changeRequestPolicy?.approvers])

  return (
    <>
      <Box sx={styles.growBox}>
        <Container maxWidth="lg" sx={styles.pageWrapper}>
          <Box id="modifyChangeRequestHeader" sx={{ pt: '12px !important', pb: '32px' }}>
            <Box sx={styles.flexCenterNone}>
              <Typography variant="h4">
                {"Change Request Details"}
              </Typography>
            </Box>
          </Box>
          <Card sx={{ ...styles.dataWrappper, mt: 0, pt: 0 }}>
            <CardContent>
              {(changeRequest.isLoading || !(searchParams && searchParams.get("type"))) ?
                <Box sx={{ py: 4 }} >
                  <Skeleton height={42} />
                  <Skeleton />
                  <Skeleton />
                </Box>
                :
                <>
                  <Grid container direction='column' spacing={2}>
                    <Grid item sx={{ display: 'flex', pb: 2 }}>
                      <Button startIcon={<ChevronLeftIcon />} variant='text' color='secondary' onClick={() => navigate(-1)}>
                        {"Back"}
                      </Button>
                      <Box sx={styles.growBox} />
                      {(hasApproverRole && isApproverCR) &&
                        <ChangeRequestMenu Component={ActionButton} row={changeRequest?.changeRequest} tab={searchParams.get("type")}  handleAction={() => navigate(-1)}/>}
                    </Grid>
                  </Grid>
                  <ChangeRequestDetails changeRequest={changeRequest?.changeRequest}/>
                </>}
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default ViewChangeRequests;
