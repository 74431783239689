import React from 'react';
import { Typography, Chip } from '@mui/material';
import { ChangeRequestMenu } from '../../components/ChangeRequestMenu';
import minorCategoryIcon from '../../assets/images/minorCategory.svg';
import majorCategoryIcon from '../../assets/images/majorCategory.svg';
import { ActionTableButton } from '../../components/ActionsMenu/ActionButton';

export const StatusBackgroundColors = {
  active: '#FFE8C5',
  approved: '#D6F5C7',
  rejected: '#FFE1D3',
  cancelled: '#ffa7a4',
};

export const columns = [
  {
    fieldName: 'designation',
    name: 'designation',
    label: 'Designation',
    type: 'string',
    filter: true,
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row?.position?.designation?.name}
      </Typography>
    ),
  },
  {
    fieldName: 'position_code',
    name: 'p',
    label: 'Position Code',
    type: 'string',
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row?.position?.positionCode}
      </Typography>
    ),
  },
  {
    fieldName: 'change_policy',
    name: 'change_policy',
    label: 'Change Policy',
    type: 'string',
    filter: true,
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row?.position?.changeRequestPolicy?.name}
      </Typography>
    ),
  },
  {
    fieldName: 'categoryType',
    name: 'c',
    label: 'Category',
    type: 'string',
    formatValue: ({ row, column }) => {
      return <div>
        {row[column.fieldName] && <img src={row[column.fieldName]?.name === "Minor" ? minorCategoryIcon : majorCategoryIcon} alt={row[column.fieldName]?.name} style={{paddingRight: "6px"}} />}
        {row[column.fieldName]?.name}
    </div>
    },
  },
  {
    fieldName: 'status',
    name: 's',
    label: 'Request Status',
    type: 'string',
    filter: true,
    formatValue: ({ row, column }) => {
      return <Chip label={row[column.fieldName]} sx={{ backgroundColor: StatusBackgroundColors[row[column.fieldName].toLowerCase()] }} />;
    },
  },
  {
    fieldName: 'submitted_by',
    name: 'submitted_by',
    label: 'Submitted by',
    type: 'string',
    filter: true,
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row?.createdBy?.employee.fullName}
      </Typography>
    ),
  },
  {
    fieldName: 'department',
    name: 'department',
    label: 'Department',
    type: 'string',
    filter: true,
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row?.position?.department?.name}
      </Typography>
    ),
  },
  {
    fieldName: 'location',
    name: 'location',
    label: 'Location',
    type: 'number',
    filter: true,
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row?.position?.location?.name}
      </Typography>
    ),
  },
  {
    id: 'action',
    label: 'Actions',
    sticky: true,
    filter: true,
    formatValue: ({ row, action }) => (
      <ChangeRequestMenu tab="approver" Component={ActionTableButton} row={row} handleAction={action} />
    ),
  },
];


export const allCRcolumns = [
  {
    fieldName: 'designation',
    name: 'designation',
    label: 'Designation',
    type: 'string',
    filter: true,
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row?.position?.designation?.name}
      </Typography>
    ),
  },
  {
    fieldName: 'position_code',
    name: 'p',
    label: 'Position Code',
    type: 'string',
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row?.position?.positionCode}
      </Typography>
    ),
  },
  {
    fieldName: 'change_policy',
    name: 'change_policy',
    label: 'Change Policy',
    type: 'string',
    filter: true,
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row?.position?.changeRequestPolicy?.name}
      </Typography>
    ),
  },
  {
    fieldName: 'categoryType',
    name: 'c',
    label: 'Category',
    type: 'string',
    formatValue: ({ row, column }) => {
      return <div>
        {row[column.fieldName] && <img src={row[column.fieldName]?.name === "Minor" ? minorCategoryIcon : majorCategoryIcon} alt={row[column.fieldName]?.name} style={{paddingRight: "6px"}} />}
        {row[column.fieldName]?.name}
      </div>;
    },
  },
  {
    fieldName: 'status',
    name: 's',
    label: 'Request Status',
    type: 'string',
    formatValue: ({ row, column }) => {
      return <Chip label={row[column.fieldName]} sx={{ backgroundColor: StatusBackgroundColors[row[column.fieldName].toLowerCase()] }} />;
    },
  },
  {
    fieldName: 'submission_date',
    name: 'submission_date',
    label: 'Submission date',
    type: 'string',
    filter: true,
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row?.createdOn}
      </Typography>
    ),
  },
  {
    fieldName: 'submitted_by',
    name: 'submitted_by',
    label: 'Submitted by',
    type: 'string',
    filter: true,
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row?.createdBy?.employee.fullName}
      </Typography>
    ),
  },
  {
    id: 'action',
    label: 'Actions',
    sticky: true,
    filter: true,
    formatValue: ({ row, action }) => (
      <ChangeRequestMenu tab="all" Component={ActionTableButton} row={row} handleAction={action} />
    ),
  },
];
