import {
  Divider,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';
import React from 'react';
import { styles } from './styles';
import { Box } from '@mui/system';
import { styles as positionStyles } from '../../pages/Positions/styles';
import { useNavigate } from 'react-router-dom';
import AuditLog from '../AuditLog';
import { UserMenu } from '../Users/UserMenu';
import { ActionButton } from '../ActionsMenu/ActionButton';

const UserData = ({ user, id }) => {
  const navigate = useNavigate();

  if (user.isLoading) {
    return (
      <Box sx={{ p: 2 }}>
        <Skeleton height={42} />
        <Skeleton height={42} />
        <Skeleton height={42} />
      </Box>
    );
  }
  const RoleKeys = Object.entries(user.roleMap).filter((item) => item.includes(true));

  const details = {
    Username: user?.secUserInstance?.username,
    'First Name': user?.employeeInstance?.firstName,
    'Last Name': user?.employeeInstance?.lastName,
    'Role': RoleKeys.map((key, i) => key[0]).join(),
    'Email ID': user?.employeeInstance?.emailAddresses?.[0],
    'Contact Number': user?.employeeInstance?.mobileNumbers?.[0],
  };

  const accountDetails = {
    'Password Expired': user?.secUserInstance?.passwordExpired ? 'Yes' : 'No',
    'Account Locked': user?.secUserInstance?.accountLocked ? 'Yes' : 'No',
    'Account Enabled': user?.secUserInstance?.enabled ? 'Yes' : 'No',
  };

  return (
    <Grid container spacing={4}>
      <Grid item md={12} xs={12}>
        <Grid
          container
          spacing={3}
          sx={{
            mb: 5,
          }}
        >
          <Grid item xs={12}>
            <Box sx={styles.titleWrapper} alignItems={'center'}>
              <Typography color='neutral.600' variant='subtitle2'>
                User Details
              </Typography>
              <Box sx={positionStyles.growBox} />
              <UserMenu Component={ActionButton} row={{id: user?.secUserInstance?.employeeId, userID: id, ...user}} 
                keyColumn='userID' handleAction={() => navigate('/users')}/>
            </Box>
          </Grid>
          {Object.keys(details).map((key) => (
            <Grid
              key={key}
              item
              xs={4}
              sx={{
                pt: '32px !important',
              }}
            >
              <Typography color='neutral.600' variant='body2'>
                {key}
              </Typography>
              {typeof details[key] === 'string' || !details[key] ? (
                <Typography
                  color='neutral.700'
                  variant='h6'
                  sx={{
                    pt: 2,
                  }}
                >
                  {details[key] || ''}
                </Typography>
              ) : (
                details[key]
              )}
            </Grid>
          ))}
          <Grid item xs={12}>
            <Box sx={styles.titleWrapper}>
              <Typography color='neutral.600' variant='subtitle2'>
                Account Details
              </Typography>
            </Box>
          </Grid>
          {Object.keys(accountDetails).map((key) => (
            <Grid
              key={key}
              item
              xs={4}
              sx={{
                pt: '32px !important',
              }}
            >
              <Typography color='neutral.600' variant='body2'>
                {key}
              </Typography>
              {typeof accountDetails[key] === 'string' || !accountDetails[key] ? (
                <Typography
                  color='neutral.700'
                  variant='h6'
                  sx={{
                    pt: 2,
                  }}
                >
                  {accountDetails[key] || ''}
                </Typography>
              ) : (
                accountDetails[key]
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <AuditLog data={user?.commentsMap} />
      </Grid>
    </Grid>
  );
};

export default UserData;
