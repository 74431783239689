import React, { useState, useMemo } from 'react';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ListIcon from '@mui/icons-material/List';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { formDataApi } from '../../axios';

export const CompanyMenu = ({Component, row, handleAction }) => {
  const [actionType, setActionType] = useState();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleDelete =async () => {
    setActionType();
    const bodyFormData = new FormData();
    bodyFormData.append('id', row.id);
    try {
      const response = await formDataApi.post('/deleteCompany', bodyFormData);
      if (response.data?.status === 'SUCCESS') {
        enqueueSnackbar('Company deleted successfuly', { variant: 'success' });
        handleAction();
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  const dialogContent = useMemo(() => {
    if(actionType === 'delete')
      return {
        title: 'Delete Company?',
        confirmation: `Are you sure you want to delete this Company?`,
        actions: <React.Fragment>
           <Button size='large' onClick={() => setActionType()}>
            Cancel
          </Button>
          <Button size='large' variant='contained' onClick={handleDelete}>
            Delete
          </Button>
        </React.Fragment>
      }
  },[actionType]);

  const actionItems = [
    {
      label: 'Modify',
      type: 'modify_company',
      icon: <EditIcon />,
      onClick: () => navigate(`/company/edit/${row.id}`),
      permissions: ['ROLE_BASE_PERMISSION_SYSTEM_INDEX_UPDATE_COMPANY'],
    },
    {
      label: 'User List',
      type: 'company_user_list',
      icon: <ListIcon />,
      onClick: () => navigate(`/company/${row.id}/users`),
    },
    {
      label: 'Configuration',
      type: 'company_configuration',
      icon: <SettingsSuggestIcon />,
      onClick: () => navigate(`/company/${row.id}/config`),
    },
    // {
    //   label: 'Delete',
    //   type: 'delete',
    //   icon: <Delete />,
    //   onClick: (action) => setActionType(action.type),
    //   permissions: ['ROLE_BASE_PERMISSION_SYSTEM_INDEX_DELETE_COMPANY'],
    // },
  ];

  return <Component actions={actionItems} dialog={dialogContent}/>
};
