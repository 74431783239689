import React, { useMemo, useState } from 'react';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Delete } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { formDataApi } from '../../axios';

export const DesignationMenu = ({Component, row, handleAction }) => {
  const [actionType, setActionType] = useState();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleDelete =async () => {
    setActionType();
    const bodyFormData = new FormData();
    bodyFormData.append('id', row.id);
    try {
      const response = await formDataApi.post('/deleteDesignation', bodyFormData);
      if (response.data?.status === 'SUCCESS') {
        enqueueSnackbar('Designation deleted successfuly', { variant: 'success' });
        handleAction();
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  const dialogContent = useMemo(() => {
    if(actionType === 'delete')
      return {
        title: 'Delete Designation?',
        confirmation: `Are you sure you want to delete this Designation?`,
        actions: <React.Fragment>
           <Button size='large' onClick={() => setActionType()}>
            Cancel
          </Button>
          <Button size='large' variant='contained' onClick={handleDelete}>
            Delete
          </Button>
        </React.Fragment>
      }
  },[actionType])

  const actionItems = [
    {
      label: 'Modify',
      type: 'modify_designation',
      icon: <EditIcon />,
      onClick: () => navigate(`/settings/designations/edit/${row.id}`),
      permissions: ['ROLE_BASE_PERMISSION_INDEX_UPDATE_DESIGNATION'],
    },
    {
      label: 'Delete',
      type: 'delete',
      icon: <Delete />,
      onClick: (action) => setActionType(action.type),
      permissions: ['ROLE_BASE_PERMISSION_INDEX_DELETE_DESIGNATION'],
    },
  ];

  return <Component actions={actionItems} dialog={dialogContent}/>
};

