import React, { useState, useMemo } from 'react';
import { Button, CircularProgress} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import {formDataApi } from "../../axios";

export const RecruitmentPolicyMenu = ({ Component, page, row, setRuleAction }) => {
  const [actionType, setActionType] = useState();
  const [resyncing, setResyncing] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  
  const handleResync = async () => {
    if(resyncing) return;
    const bodyFormData = new FormData();
    bodyFormData.append('recruitmentPolicyId', row.id);
    try {
      setResyncing(true)
      const response = await formDataApi.post('/resyncSingleRecruitmentPolicy',bodyFormData);
      if (response?.data && response?.data?.status === "SUCCESS"){
        enqueueSnackbar(response?.data?.message, { variant: 'success' });
      }else enqueueSnackbar(response?.data?.message, { variant: 'error' });
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      setActionType()
      setResyncing(false)
    }
  }

  const dialogContent = useMemo(() => {
    if(actionType === "addRule") return setRuleAction("add")
    else if (actionType === 'resync')
      return {
        title: 'Resync Recruitment Policy',
        confirmation: `Are you sure you want to resync this Recruitment Policy with Positions?`,
        actions: <React.Fragment>
           <Button size='medium' variant='text' onClick={() => setActionType()} disabled={resyncing}>
            No
          </Button>
          <Button size='medium' variant='contained' onClick={handleResync} startIcon={resyncing ? <CircularProgress  color='inherit' size='1.2rem' /> : <></>}>
            Yes
          </Button>
        </React.Fragment>
      }
    },[actionType, resyncing]);

  const actionItems = [
    page === "list" && {
      label: 'Modify',
      type: 'modify',
      icon: <EditIcon />,
      onClick: () => navigate(`/recruitmentPolicies/${row.id}`),
      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_PRE_CREATE_RULES_FOR_RECRUITMENT_POLICY'],
    },
    page === "view" && row?.recruitmentPolicyStatus?.toLowerCase() === "active" && {
      label: 'Add Rule',
      type: 'addRule',
      icon: <AddIcon />,
      onClick: (action) => setActionType(action.type),
      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_SAVE_RULES_FOR_RECRUITMENT_POLICY'],
    },
    {
      label: 'Resync',
      type: 'resync',
      icon: <AutorenewIcon />,
      onClick: (action) => setActionType(action.type),
      permissions: ['ROLE_BASE_PERMISSION_RECRUIT_INDEX_RESYNC_SINGLE_RECRUITMENT_POLICY'],
    },
  ];

  return <Component actions={actionItems} dialog={dialogContent}/>
};