import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { ChangeRequestFilter } from '../../../components/ChangeRequests/ChangeRequestFilter';
import { getChangeRequests, updateCRs } from '../../../store/slices/listChangeRequests';
import CustomTable from '../../../components/CustomTable';
import { allCRcolumns } from '../columns';
import { updateAllSelectedCRs, updateSelectedCRs, resetSelectedCRs } from '../../../store/slices/listChangeRequests';
import { formDataApi } from '../../../axios';
import { endpoints } from '../../../store/constants';
import { clearFilters, onPageChange, onPageSizeChange, onSortChange, setFilters } from '../../../store/slices/tableController';

const AllChangeRequests = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  
  const controller = useSelector((state) => state.tableController);

  const dispatch = useDispatch();
  const { changeRequests, selectedCRs, isLoading } = useSelector((state) => state.changeRequests);

  const fetchData = useCallback(async (searched, filters, sort, sortBy, page, pageSize) => {
    setLoading(true);
    try {
      if (searched) {
        const query = new URLSearchParams({});
        if (filters.length) {
          filters.forEach((filter) => query.append(filter.property, filter.value));
        }
        if (sortBy) {
          query.append('sort', sortBy);
          query.append('order', sort);
        }
        query.append('offset', page * pageSize);
        query.append('max', pageSize);
        const response = await formDataApi.post(`${endpoints.SEARCH_CHANGE_REQUEST_ACTION}?${query.toString()}`);
        if (response.status === 200) {
          dispatch(updateCRs(response.data));
        }
      } else {
        const query = new URLSearchParams({});
        if (sortBy) {
          query.append('sort', sortBy);
          query.append('order', sort);
        }
        query.append('offset', page * pageSize);
        query.append('max', pageSize);
        dispatch(
          getChangeRequests({
            query,
          })
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);


  useEffect(() => {
    fetchData(controller.searched, controller.filters, controller.sort, controller.sortBy, controller.page, controller.pageSize);
  }, [controller.searched, controller.filters, controller.sort, controller.sortBy, controller.page, controller.pageSize]);

  useEffect(() => {
    dispatch(resetSelectedCRs());
  }, []);

  const handleFiltersApply = (newFilters) => {
    const parsedFilters = newFilters.map((filter) => ({
      property: filter.property.name,
      value: filter.value,
      operator: filter.operator.value,
    }));

    dispatch(setFilters(parsedFilters));
  };

  const handleFiltersClear = () => {
    dispatch(clearFilters());
  };
  
  const handlePageChange = (newPage) => {
    dispatch(onPageChange(newPage - 1));
  };

  const handlePageSizeChange = (newPageSize) => {
    dispatch(onPageSizeChange(newPageSize));
  }

  const handleSortChange = (event, property) => {
    const isAsc = controller.sortBy === property && controller.sort === 'asc';
    dispatch(onSortChange({
      sort: isAsc ? 'desc' : 'asc',
      sortBy: property
    }));
  };

  const handleSelect = (event, rowId) => {
    dispatch(updateSelectedCRs({ isChecked: event.target.checked, rowId }));
  };

  const handleSelectAll = (event) => {
    dispatch(updateAllSelectedCRs({ isChecked: event.target.checked }));
  };

  return (
    <>
      <ChangeRequestFilter
        tab={'all'}
        disabled={false}
        filters={controller.filters}
        onFiltersApply={handleFiltersApply}
        onFiltersClear={handleFiltersClear}
        selectedChangeRequests={selectedCRs}
        filterProperties={allCRcolumns}
        initialFilters={controller.initialFilters}
      />
      <CustomTable
        isLoading={isLoading || loading}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        onSelect={handleSelect}
        onSelectAll={handleSelectAll}
        onSortChange={handleSortChange}
        page={controller.page + 1}
        onRowClick={(row) => {
          navigate(`/changeRequests/${row}?type=all`);
        }}
        columns={allCRcolumns}
        rows={changeRequests?.changeRequests}
        rowsCount={changeRequests?.changeRequestCount}
        selectedRows={selectedCRs}
        sort={controller.sort}
        sortBy={controller.sortBy}
        showPagignation
        keyColumn='id'
        route='changerequests'
        action={() => fetchData(controller.searched, controller.filters, controller.sort, controller.sortBy, controller.page, controller.pageSize)}
      />
    </>
  );
};

export default AllChangeRequests;
