import React from 'react';
import { Typography } from '@mui/material';
import { Chip } from '@mui/material';
import { ActionTableButton } from '../../components/ActionsMenu/ActionButton';
import { CompanyMenu } from '../../components/Company/CompanyMenu';

export const columns = [
  {
    fieldName: 'companyName',
    name: 't',
    label: 'COMPANY NAME',
    type: 'string',
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row[column.fieldName]}
      </Typography>
    ),
  },
  {
    fieldName: 'positionCount',
    name: 'positionCount',
    label: 'NO. OF POSITIONS',
    type: 'string',
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row[column.fieldName]}
      </Typography>
    ),
  },
  {
    fieldName: 'customerStatus',
    name: 'status',
    label: 'STATUS',
    type: 'string',
    formatValue: ({ row, column }) => (
      <>
        <Chip
          label={row.customer[column.fieldName]}
          sx={{ backgroundColor: row.customer[column.fieldName].toLowerCase() === 'active' ? '#BCE2E2' : '#FFE1D3' }}
          props
        />
      </>
    ),
  },
  {
    fieldName: 'isIntegratedWithCanvas',
    name: 'isIntegratedWithCanvas',
    label: 'Canvas Integration',
    type: 'string',
    formatValue: ({ row, column }) => (
      <>
        <Chip
          label={row.config[column.fieldName] ? "Active" : "Inactive"}
          sx={{ backgroundColor: row.config[column.fieldName] ? '#BCE2E2' : '#FFE1D3' }}
          props
        />
      </>
    ),
  },
  {
    fieldName: 'isClientDisengaged',
    name: 'isClientDisengaged',
    label: 'Client Disengagement',
    type: 'string',
    formatValue: ({ row, column }) => (
      <>
        <Chip
          label={row[column.fieldName] ? "Active" : "Inactive"}
          sx={{ backgroundColor: row[column.fieldName] ? '#BCE2E2' : '#FFE1D3' }}
          props
        />
      </>
    ),
  },
  {
    fieldName: 'createdOn',
    name: 'createdOn',
    label: 'Created Date',
    type: 'string',
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row?.createdOn}
      </Typography>
    ),
  },
  {
    id: 'action',
    label: 'Actions',
    filter: false,
    sticky: true,
    formatValue: ({ row, action }) =>
      <CompanyMenu Component={ActionTableButton} row={row} handleAction={action} />,
  },
];
