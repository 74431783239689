import React, { useCallback, useEffect, useState } from 'react';
import { formDataApi } from '../../axios';
import { Box, Button, Card, CardContent, Container, Grid, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress } from '@mui/material';
import PermissionChecker from '../../components/PermissionChecker';
import { useNavigate } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CustomTable from '../../components/CustomTable';
import { columns } from './columns';
import { styles } from './styles';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

const RecruitmentPolicy = () => {
    const [recruitmentPolicy, setRecruitmentPolicy] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [resyncing, setResyncing] = useState(false);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useSelector((state) => state.session);

    const fetchData = useCallback(async (isRefresh) => {
        setLoading(true);
        try {
            const bodyFormData = new FormData();
            bodyFormData.append('refresh', isRefresh);
            const response = await formDataApi.post(`/fetchRecruitmentPolicy`, bodyFormData);
            if (response.data && response.data?.status !== "ERROR") setRecruitmentPolicy(response.data?.recruitmentPolicies || [])
            else enqueueSnackbar(response?.data?.message, { variant: 'error' }); 
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }, []);
    

    useEffect(() => {
        fetchData(false);
    },[])

    const handleRowClick = (id) => {
      navigate(`/recruitmentPolicies/${id}`);
    };

    const handleRefresh = () => {
      fetchData(true)
    }

    const handleBulkResync = async () => {
      if(resyncing) return;
      const bodyFormData = new FormData();
      bodyFormData.append('companyId', user.companyDBID);
      try {
        setResyncing(true)
        const response = await formDataApi.post('/resyncAllPositionsWithAllPolicies',bodyFormData);
        if (response?.data && response?.data?.status === "SUCCESS"){
          enqueueSnackbar(response?.data?.message, { variant: 'success' });
        }else enqueueSnackbar(response?.data?.message, { variant: 'error' });
      } catch (err) {
        enqueueSnackbar(err.message, { variant: 'error' });
      } finally {
        setOpenDialog(false)
        setResyncing(false)
      }
    }

    return (
      <Box sx={styles.growBox}>
        <Container maxWidth='lg' sx={styles.pageWrapper}>
          <Grid container direction='column' spacing={0}>
            <Grid
              sx={{pl: '8px', pt: '12px', pb: '32px' }}
              item
              container
              spacing={1}
              justifyContent='flex-start'
              alignItems='center'
              py={4}
            >
              <Box sx={styles.flexCenterNone} width={"100%"}>
                <Typography variant='h4'>Recruitment Policy</Typography>
                <Box sx={styles.growBox} />
                <PermissionChecker
                  requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_RESYNC_ALL_POSITIONS_WITH_ALL_RECRUITMENT_POLICIES']}
                  noAuthRedirect={false}
                >
                  <Button
                    color='primary'
                    sx={{mr: "15px"}}
                    onClick={() => setOpenDialog(true)}
                    size='large'
                    startIcon={<AutorenewIcon fontSize='small' />}
                    variant='contained'
                  >
                    Bulk Resync
                  </Button>
                </PermissionChecker>
                <PermissionChecker
                  requiredPermissions={['ROLE_BASE_PERMISSION_RECRUIT_INDEX_GET_RECRUITMENT_POLICY_DETAILS_FOR_CANVAS']}
                  noAuthRedirect={false}
                >
                  <Button
                    color='primary'
                    onClick={handleRefresh}
                    size='large'
                    startIcon={<RefreshIcon fontSize='small' />}
                    variant='contained'
                  >
                    Refresh
                  </Button>
                </PermissionChecker>
              </Box>
            </Grid>
            <Grid item sx={{ pt: '0px !important' }}>
              <Card>
                <CardContent>
                  <CustomTable
                    isLoading={isLoading}
                    onRowClick={handleRowClick}
                    columns={columns}
                    rows={recruitmentPolicy}
                    showRowSelector={false}
                    showPagignation={false}
                    keyColumn='id'
                    action={() => fetchData(false)}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
        <Dialog open={openDialog} >
            <DialogContent sx={styles.tableMenuDialog}>
            <Box sx={styles.tableMenuDialog}>
                <DialogTitle>Resync Bulk Recruitment Policy</DialogTitle>
                <DialogContentText>Are you sure you want to resync bulk Recruitment Policy with Positions?</DialogContentText>
            </Box>
            <DialogActions>
                <Button size='medium' variant='text' onClick={() => setOpenDialog(false)} disabled={resyncing}>
                No
                </Button>
                <Button size='medium' variant='contained' onClick={handleBulkResync} startIcon={resyncing ? <CircularProgress  color='inherit' size='1.2rem' /> : <></>}>
                Yes
                </Button>
            </DialogActions>
            </DialogContent>
        </Dialog>
      </Box>
    )
}
export default RecruitmentPolicy;