import { Button, FormHelperText, Grid, MenuItem, Divider, Stack } from '@mui/material';
import { InputField } from '../InputField';
import { useSelector } from 'react-redux';
import { hasPermission } from '../../utils/permission';

const PositionDetailsTab = ({ preCreationDetails, formik, isCreate = false, isBulkModify = false, isModify = false }) => {
  const { departmentsList = [], designationsList = [], locationsList = [], position } = preCreationDetails || {};

  const designationOptions = designationsList.map((item) => ({ id: item.id, name: item.name }));
  const departmentOptions = departmentsList.map((item) => ({ id: item.id, name: item.name }));
  const locationOptions = locationsList.map((item) => ({ id: item.id, name: item.name }));

  const authorities = useSelector((state) => state.session.user.authorities);
  const userHasCompaCreatePermisson = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_RECRUIT_INDEX_COMPA_CREATE_POSITION',
  ]);
  const userHasCompaModifyPermisson = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_RECRUIT_INDEX_COMPA_UPDATE_POSITION',
  ]);

  return (
    <Grid container spacing={4}>
      <Grid item md={12} xs={12}>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <Grid container spacing={3}>
              {/* <Grid item xs={4}>
                <InputField
                  error={Boolean(formik.touched.title && formik.errors.title)}
                  fullWidth
                  helperText={formik.touched.title && formik.errors.title}
                  label='Title'
                  name='title'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.title}
                />
              </Grid> */}
              {/*!isBulkModify && (
                <Grid item xs={4}>
                  <InputField
                    error={Boolean(formik.touched.positionId && formik.errors.positionId)}
                    fullWidth
                    helperText={formik.touched.positionId && formik.errors.positionId}
                    label='Position ID'
                    name='positionId'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.positionId}
                  />
                </Grid>
              )*/}
              <Grid item xs={4}>
                <InputField
                  error={Boolean(formik.touched.designation && formik.errors.designation)}
                  fullWidth
                  helperText={formik.touched.designation && formik.errors.designation}
                  label='Designation'
                  name='designation'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  select
                  value={formik.values.designation}
                >
                  {designationOptions.map((designationOption) => (
                    <MenuItem key={designationOption.id} value={designationOption.id}>
                      {designationOption.name}
                    </MenuItem>
                  ))}
                </InputField>
              </Grid>
              {(((isModify && !position.externalEntityID && position.status === "Draft") && !isBulkModify) || isCreate) && (
                <Grid item xs={4}>
                  <InputField
                    error={Boolean(formik.touched.positionCode && formik.errors.positionCode)}
                    fullWidth
                    helperText={formik.touched.positionCode && formik.errors.positionCode}
                    label='Position Code'
                    name='positionCode'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.positionCode}  
                  />
                </Grid>
              )}
              <Grid item xs={4}>
                <InputField
                  error={Boolean(formik.touched.department && formik.errors.department)}
                  fullWidth
                  helperText={formik.touched.department && formik.errors.department}
                  label='Department (Optional)'
                  name='department'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik?.values?.department}
                  select
                >
                  {departmentOptions.map((departmentOption) => (
                    <MenuItem key={departmentOption.id} value={departmentOption.id}>
                      {departmentOption.name}
                    </MenuItem>
                  ))}
                </InputField>
              </Grid>
              <Grid item xs={4}>
                <InputField
                  error={Boolean(formik.touched.location && formik.errors.location)}
                  fullWidth
                  helperText={formik.touched.location && formik.errors.location}
                  label='Location (Optional)'
                  name='location'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.location}
                  select
                >
                  {locationOptions.map((departmentOption) => (
                    <MenuItem key={departmentOption.id} value={departmentOption.id}>
                      {departmentOption.name}
                    </MenuItem>
                  ))}
                </InputField>
              </Grid>
              {(userHasCompaCreatePermisson || userHasCompaModifyPermisson) && (
                <>
                  <Grid item xs={4}>
                    <InputField
                      error={Boolean(formik.touched.budgetLowerLimit && formik.errors.budgetLowerLimit)}
                      fullWidth
                      helperText={formik.touched.budgetLowerLimit && formik.errors.budgetLowerLimit}
                      label='Budget Lower Limit'
                      name='budgetLowerLimit'
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.budgetLowerLimit}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <InputField
                      error={Boolean(formik.touched.budgetMidLimit && formik.errors.budgetMidLimit)}
                      fullWidth
                      helperText={formik.touched.budgetMidLimit && formik.errors.budgetMidLimit}
                      label='Budget Mid Limit'
                      name='budgetMidLimit'
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.budgetMidLimit}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <InputField
                      error={Boolean(formik.touched.budgetUpperLimit && formik.errors.budgetUpperLimit)}
                      fullWidth
                      helperText={formik.touched.budgetUpperLimit && formik.errors.budgetUpperLimit}
                      label='Budget Upper Limit'
                      name='budgetUpperLimit'
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.budgetUpperLimit}
                    />
                  </Grid>
                </>
              )}
              {formik.errors.submit && (
                <Grid item xs={12}>
                  <FormHelperText error>{formik.errors.submit}</FormHelperText>
                </Grid>
              )}
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Stack direction='row' justifyContent='end'>
                  {/*!isBulkModify && (
                    <Button
                      color='primary'
                      size='large'
                      variant='text'
                      sx={{
                        mr: 2,
                      }}
                    >
                      Save as draft
                    </Button>
                    )*/}
                  <Button color='primary' size='large' type='submit' variant='contained'>
                    Next
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </div>
        </form>
      </Grid>
    </Grid>
  );
};

export default PositionDetailsTab;
