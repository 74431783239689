import { useLocation, matchPath } from 'react-router-dom';

const pageTitles = {
  '/login': 'Login',
  '/forgot-password': 'Forgot Password',
  '/reset-password/:id': 'Reset Password',
  '/dashboard': 'Dashboard',
  '/dashboard/account': 'User Profile',
  '/dashboard/account/update/:id': 'Update User Profile',
  '/positions': 'List Positions',
  '/positions/add': 'Add Position',
  '/positions/:id': 'View Position',
  '/positions/modify/:id': 'Modify Position',
  '/changerequests': 'List Change Requests',
  '/changerequest/crpolicies': 'List CR Policies',
  '/changerequest/crpolicies/add': 'Add CR Policies',
  '/recruitmentPolicies': 'List Recruitment Policies',
  '/users': 'List Users',
  '/users/add': 'Add User',
  '/users/:id': 'View User',
  '/settings/locations': 'List Locations',
  '/settings/locations/add': 'Add Location',
  '/settings/locations/:id': 'View Location',
  '/settings/designations': 'List Designations',
  '/settings/designations/add': 'Add Designation',
  '/settings/designations/:id': 'View Designation',
  '/settings/demand-attributes': 'Demand Attributes',
};

export const usePageTitles = () => {
  const location = useLocation();

  const currentPageTitle = Object.keys(pageTitles).find((key) => {
    if (matchPath({ path: key, exact: true }, location.pathname)) {
      return true;
    }
    return false;
  });

  return pageTitles[currentPageTitle];
};
