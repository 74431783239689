import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Typography } from '@mui/material';
import { usePopover } from '../../hooks/use-popover';
import { ChevronDown as ChevronDownIcon } from '../../assets/icons/chevron-down';
import { DotsVertical as DotsVerticalIcon } from '../../assets/icons/dots-vertical';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { styles } from './styles';
import { hasPermission } from '../../utils/permission';

const ActionMenu = (props) => {
  const { filteredActions, dialog, anchorRef, open, handleClose, style } = props;
  const [ openDialog, setOpenDialog ] = useState(false);

  useEffect(() => {
    setOpenDialog(dialog ? true : false);
  },[dialog]);

  return (
    <React.Fragment>
      <Menu
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open ? open : false}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList dense>
            {filteredActions?.map((item) => (
                <MenuItem
                    key={item.label}
                    onClick={() => {
                        if (item.onClick) item.onClick(item);
                        handleClose();
                    }}
                >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText variant='body2'>{item.label}</ListItemText>
                </MenuItem>
            ))}
        </MenuList>
      </Menu>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="sm" fullWidth {...style?.dialog}>
        <DialogTitle sx={{textAlign: "center"}}>{dialog?.title}</DialogTitle>
        <DialogContent sx={styles.tableMenuDialog}>
            <DialogContentText>
                {dialog?.subTitle && <Typography variant='h6' mb={2}>{dialog?.subTitle}</Typography>}
                {dialog?.confirmation && dialog?.confirmation}
            </DialogContentText>
            {dialog?.children}
        <DialogActions sx={{width: "100%", pr: "0px"}}>
            {dialog?.actions}
        </DialogActions>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export const ActionTableButton = (props) => {
    const { disabled, actions} = props;
    const { user } = useSelector((state) => state.session);
    const [anchorRef, open, handleOpen, handleClose] = usePopover();
    const filteredActions = useMemo(() => {
        return actions.filter(action => {
            if(!action) return false;
            if(Array.isArray(action.permissions))
                return hasPermission(user.authorities || [], action.permissions)
            else return true
        })
    },[actions])

    return <React.Fragment>
        <IconButton
            onClick={handleOpen}
            ref={anchorRef}
            color='primary'
            disabled={disabled || filteredActions.length <= 0}
        >
            <DotsVerticalIcon fontSize='small' />
        </IconButton>
        <ActionMenu {...props} filteredActions= {filteredActions} anchorRef={anchorRef} open={open} handleClose={handleClose}/>
    </React.Fragment>
}

export const ActionButton = (props) => {
    const { disabled, actions, label} = props;
    const { user } = useSelector((state) => state.session);
    const [anchorRef, open, handleOpen, handleClose] = usePopover();
    const filteredActions = useMemo(() => {
        return actions.filter(action => {
            if(!action) return false;
            if(Array.isArray(action.permissions))
                return hasPermission(user.authorities || [], action.permissions)
            else return true
        })
    },[actions])

    return filteredActions?.length > 0 && <React.Fragment>
       <Button
        color='primary'
        endIcon={<ChevronDownIcon fontSize='small' />}
        onClick={handleOpen}
        size='large'
        variant='contained'
        ref={anchorRef}
        disabled={disabled}
        >
            {label || "Actions"}
        </Button>
        <ActionMenu {...props} filteredActions= {filteredActions} anchorRef={anchorRef} open={open} handleClose={handleClose}/>
    </React.Fragment>
}