import React, { useCallback, useState, useMemo } from "react";
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CheckOutlined } from "@mui/icons-material";
import { bulkUpdateCRStatuses, getApproverChangeRequests, getChangeRequests, resetSelectedCRs } from "../../store/slices/listChangeRequests";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

export const ChangeRequestBulkActionMenu = ({Component, tab, selectedCount, label }) => {
  const [actionType, setActionType] = useState();
  const [comment, setComment] = useState('');
  const { selectedCRs } = useSelector((state) => state.changeRequests);
  const currentUser = useSelector((state) => state.session.user);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const reFetchRequests = () => {
    const newBody = new FormData();
    newBody.append('s', 'Active');
    newBody.append('a', currentUser?.empDBID)
    const query = new URLSearchParams({});
    query.append('offset', 0);
    query.append('max', 10);

    dispatch(getApproverChangeRequests({
      query,
      body: newBody
    }))

    dispatch(getChangeRequests({
      query
    }))
  }

  const handleConfirmStatus = (status) => {
    setActionType();
    const body = new FormData();
    body.append('status', status);
    body.append('ids', selectedCRs.join(", "));
    comment?.length > 0 && body.append('comment', comment);
    dispatch(bulkUpdateCRStatuses({
      body
    }))
    .unwrap()
    .then(res => {
      if(res?.status === "SUCCESS"){
        enqueueSnackbar(res?.message, { variant: 'success' });
        reFetchRequests()
      }else 
        enqueueSnackbar(res?.message, { variant: 'error' });
    }).catch((err) => {
      enqueueSnackbar("Failed to update change request.", { variant: "error" });
    }).finally(() => 
      dispatch(resetSelectedCRs())
    )
  }

  const dialogContent = useMemo(() => {
    if(actionType === 'approve')
      return {
        title: 'Bulk approve change requests',
        subTitle: `Are you sure you want to approve ${selectedCount} change requests?`,
        actions: <React.Fragment>
           <Button size='large' onClick={() => setActionType()}>
            Cancel
          </Button>
          <Button size='large' variant='contained' onClick={() => handleConfirmStatus("Approved")}>
            Confirm
          </Button>
        </React.Fragment>
      }
    else if(actionType === 'edit')
      return {
        title: 'Modify Change Requests',
        children: <React.Fragment>
          <FormControl fullWidth>
            <InputLabel id="hm">Hiring Manager</InputLabel>
            <Select
              labelId="hm"
              id="demo-simple-select"
              // value={}
              label="Hiring Manager"
            // onChange={handleChange}
            >
              <MenuItem value='reason'>Reason</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mt: '0px !important' }}>
            <InputLabel id="rm">Recruitment manager</InputLabel>
            <Select
              labelId="rm"
              id="demo-simple-select"
              // value={}
              label="Recruitment manager"
            // onChange={handleChange}
            >
              <MenuItem value='reason'>Reason</MenuItem>
            </Select>
          </FormControl>
        </React.Fragment>,
        actions: <React.Fragment>
            <Button size='large' onClick={() => setActionType()}>
            Cancel
          </Button>
          <Button size='large' variant='contained'>
            Confirm
          </Button>
        </React.Fragment>
      }
    else if(actionType === 'reject')
      return {
        title: 'Bulk Reject Change Requests',
        subTitle: `Are you sure you want to reject ${selectedCount} change requests?`,
        children: <TextField
          fullWidth
          sx={{mt: "15px"}}
          label="Comment"
          placeholder="Type here"
          size="small"
          variant="outlined"
          onChange={(e) => setComment(e.target.value)}
          multiline
          rows={5}
        />,
        actions: <React.Fragment>
            <Button size='large' onClick={() => setActionType()}>
            Cancel
          </Button>
          <Button size='large' variant='contained' onClick={() => handleConfirmStatus("Rejected")}>
            Confirm
          </Button>
        </React.Fragment>
      }
  },[actionType, selectedCount, comment])

  const actionItems = tab === "approver" ? [
    {
      label: 'Approve',
      type: 'approve',
      icon: <CheckOutlined />,
      onClick: (action) => setActionType(action.type),
    },
    {
      label: 'Reject',
      type: 'reject',
      icon: <CloseIcon />,
      onClick: (action) => setActionType(action.type),
    },
    // {
    //   label: 'Edit',
    //   type: 'edit',
    //   icon: <EditIcon />,
    //   onClick: (action) => setActionType(action.type),
    // },
  ] : [];

  return <Component label={label} actions={actionItems} dialog={dialogContent}/>
};