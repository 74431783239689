import { Grid, Skeleton, Typography, Divider } from '@mui/material';
import { useEffect } from 'react';
import { styles } from './styles';
import { Box } from '@mui/system';
import { styles as positionStyles } from '../../pages/Positions/styles';
import { Status } from '../Status';
import { useParams } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { PositionStatusChip } from '../Position/PositionStatusChip';
import moment from 'moment';
import AuditLog from '../AuditLog';
import { useSelector } from 'react-redux';
import { hasPermission } from '../../utils/permission';
import { searchChangeCRPolicesAction } from '../../store/slices/listCRPolicies';
import { useDispatch } from 'react-redux';
import { PositionMenu } from '../Position/PositionMenu';
import { ActionButton } from '../ActionsMenu/ActionButton';

const PositionData = ({fetchData, ...props}) => {
  const authorities = useSelector((state) => state.session.user.authorities);
  const dispatch = useDispatch();
  const params = useParams();

  const userHasCompaDisplayPermisson = hasPermission(authorities, [
    'ROLE_BASE_PERMISSION_RECRUIT_INDEX_COMPA_VIEW_POSITION',
  ]);
  const position = {...props.position?.positionInstance, id: params?.id};
  const customObjectInstance = props.position?.customObjectInstance;

  useEffect(() => {
    const data = new FormData();
    data.append('s', 'Active');
    data.append('all', true);
    dispatch(searchChangeCRPolicesAction(data));
  }, [dispatch]);

  if (props.position.isLoading) {
    return (
      <Box sx={{ p: 2 }}>
        <Skeleton height={42} />
        <Skeleton height={42} />
        <Skeleton height={42} />
      </Box>
    );
  }

  const compaFields = userHasCompaDisplayPermisson
    ? {
      'Budget Lower Limit': position?.budgetLowerLimit.toString(),
      'Budget Mid Limit': position?.budgetMidLimit.toString(),
      'Budget Upper Limit': position?.budgetUpperLimit.toString(),
    }
    : {};

  const backgroundColors = {
    'Not Integrated': '#0F9292',
    Pending: '#0F9292',
    'In Process': '#EFA12D',
    Successful: '#27AB6E',
    'Failed': '#EC4C47',
  };

  const details = {
    // Title: position?.title,
    Designation: position?.designation && position?.designation?.name,
    'Position Code': position?.positionCode,
    Location: position?.location && position?.location?.name,
    Department: position?.department && position?.department?.name,
    ...compaFields,
    Status: <PositionStatusChip label={position?.status} size='small' />,
    // 'Publication Status': (
    //   <Status
    //     color={position?.publicationStatus !== 'Unpublished' ? '#27AB6E' : '#EFA12D'}
    //     label={position?.publicationStatus}
    //   />
    // ),
    'CANVAS INT': (
      <Status color={backgroundColors[position?.canvasIntegrationStatus]} label={position?.canvasIntegrationStatus} 
        icon={position?.canvasIntegrationStatus === "Failed" 
        ? <InfoOutlinedIcon sx={{ color:  backgroundColors[position?.canvasIntegrationStatus], fontSize: "16px", paddingLeft: "2px"}} titleAccess={position?.integrationErrorMessage}/> 
        : null}/>
    ),
    'Filled Date': position?.filledOn,
    'Position Start Date': position?.startDate ?  moment(position?.startDate).format('MMM DD YYYY') : '',
  };

  const additionalDetails = {
    'Change Policy': position?.changeRequestPolicy && position?.changeRequestPolicy?.name,
    'Recruitment Policy': position?.recruitmentPolicy && position?.recruitmentPolicy?.name,
    'Position Created Date': position?.createdOn ?  moment(position?.createdOn).format('MMM DD YYYY') : '',
    'Position Created By': position?.createdBy && position?.createdBy?.fullName,
    'Position Modified Date': position?.modifiedOn ?  moment(position?.modifiedOn).format('MMM DD YYYY') : '',
    'Position Modified By': position?.modifiedBy && position?.modifiedBy?.fullName,
    'Position Published Date': position?.publishedOn ?  moment(position?.publishedOn).format('MMM DD YYYY') : '',
    'Position Published By': position?.publishedBy && position?.publishedBy?.fullName,
  };

  const customAttributes = {};
  if (customObjectInstance?.customFieldValues) {
    customObjectInstance.customFieldValues.forEach((item) => {
      customAttributes[item.fieldLabel] =
        item['fieldDataType'] === 'Date'
          ? !!item['fieldValue']
            ? moment(item['fieldValue']).format('MMM DD YYYY')
            : ''
          : item['fieldValue'];
    });
  }

  return (
    <Grid container spacing={4}>
      <Grid item md={12} xs={12}>
        <Grid
          container
          spacing={3}
          sx={{
            mb: 5,
          }}
        >
          <Grid item xs={12}>
            <Box sx={styles.titleWrapper}>
              <Typography color='neutral.600' variant='subtitle2'>
                Position Details
              </Typography>
              <Box sx={positionStyles.growBox} />
              <PositionMenu Component={ActionButton} row={position} handleAction={fetchData}/>
            </Box>
          </Grid>
          {Object.keys(details).map((key) => (
            <Grid
              key={key}
              item
              xs={4}
              sx={{
                pt: '32px !important',
              }}
            >
              <Typography color='neutral.600' variant='body2'>
                {key}
              </Typography>
              {typeof details[key] === 'string' || !details[key] ? (
                <Typography
                  color='neutral.700'
                  variant='h6'
                  sx={{
                    pt: 2,
                  }}
                >
                  {details[key] || '-'}
                </Typography>
              ) : (
                details[key]
              )}
            </Grid>
          ))}
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        sx={{
          pt: '0 !important',
        }}
        item
        md={12}
        xs={12}
      >
        <Grid
          container
          spacing={3}
          sx={{
            mb: 5,
          }}
        >
          <Grid item xs={12}>
            <Box>
              <Typography color='neutral.600' variant='subtitle2'>
                Custom Attributes
              </Typography>
            </Box>
          </Grid>
          {Object.keys(customAttributes).map((key) => (
            <Grid
              key={key}
              item
              xs={4}
              sx={{
                pt: '32px !important',
              }}
            >
              <Typography color='neutral.600' variant='body2'>
                {key}
              </Typography>
              {typeof customAttributes[key] === 'string' || !customAttributes[key] ? (
                <Typography
                  color='neutral.700'
                  variant='h6'
                  sx={{
                    pt: 2,
                  }}
                >
                  {customAttributes[key] || '-'}
                </Typography>
              ) : (
                customAttributes[key]
              )}
            </Grid>
          ))}
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        sx={{
          pt: '0 !important',
        }}
        item
        md={12}
        xs={12}
      >
        <Grid
          container
          spacing={3}
          sx={{
            mb: 5,
          }}
        >
          <Grid item xs={12}>
            <Box>
              <Typography color='neutral.600' variant='subtitle2'>
                Additional Details
              </Typography>
            </Box>
          </Grid>
          {Object.keys(additionalDetails).map((key) => (
            <Grid
              key={key}
              item
              xs={4}
              sx={{
                pt: '32px !important',
              }}
            >
              <Typography color='neutral.600' variant='body2'>
                {key}
              </Typography>
              {typeof additionalDetails[key] === 'string' || !additionalDetails[key] ? (
                <Typography
                  color='neutral.700'
                  variant='h6'
                  sx={{
                    pt: 2,
                  }}
                >
                  {additionalDetails[key] || '-'}
                </Typography>
              ) : (
                additionalDetails[key]
              )}
            </Grid>
          ))}
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <AuditLog data={props.position?.commentsMap} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PositionData;
