import React from 'react';
import { Typography, Chip } from '@mui/material';
import { DepartmentMenu } from '../../components/Department/DepartmentMenu';
import { ActionTableButton } from '../../components/ActionsMenu/ActionButton';

export const columns = [
  {
    fieldName: 'name',
    name: 'name',
    label: 'Name',
    type: 'string',
    formatValue: ({ row, column }) => (
      <Typography variant='body2' noWrap>
        {row[column.fieldName]}
      </Typography>
    ),
  },
  {
    fieldName: 'status',
    name: 'status',
    label: 'Status',
    sticky: true,
    filter: true,
    formatValue: ({ row, column }) => (
      <Chip
        label={row[column.fieldName]}
        sx={{ backgroundColor: row[column.fieldName] === 'Active' ? '#BCE2E2' : '#F3F4F7' }}
      />
    ),
  },
  {
    id: 'action',
    label: 'Actions',
    sticky: true,
    filter: true,
    formatValue: ({ row, action }) => (
      <DepartmentMenu Component={ActionTableButton} row={row} handleAction={action}/>
    ),
  },
];
